<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="blog" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/vimc diamond.png"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('blogs') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->

    <div class="transx_page_content_wrapper">
      <!-- About Start -->
      <div class="blog">
        <div class="container">
          <div class="row align-items-center">
            <div
              id="post-36"
              class="post-36 page type-page status-publish has-post-thumbnail hentry"
            >
              <div class="transx_page_content_container">
                <div class="transx_page_content_wrapper transx_page_title_show">
                  <div class="container">
                    <div class="row transx_sidebar_right transx_bg_color_alt">
                      <div class="col-lg-8 col-xl-8">
                        <div class="transx_content_wrapper">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id="36"
                            class="elementor elementor-36"
                            data-elementor-settings="[]"
                          >
                            <div class="elementor-section-wrap">
                              <section
                                class="elementor-section-blog elementor-top-section elementor-element elementor-element-3561e36f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3561e36f"
                                data-element_type="section"
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54b91072"
                                    data-id="54b91072"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-43bce01d elementor-widget elementor-widget-transx_blog_listing"
                                        data-id="43bce01d"
                                        data-element_type="widget"
                                        data-widget_type="transx_blog_listing.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div
                                            class="transx_blog_listing_widget"
                                          >
                                            <div
                                              class="transx_blog_listing_wrapper transx_view_type_1"
                                            >
                                              <div
                                                class="transx_blog_listing_item"
                                              >
                                                <div
                                                  class="transx_blog_listing_image_container"
                                                >
                                                  <span
                                                    style="
                                                      position: absolute;
                                                      bottom: 0;
                                                      padding: 8px 15px;
                                                      background-color: #e8734e;
                                                      font-size: 14px;
                                                      font-weight: 700;
                                                      color: aliceblue;
                                                      border-radius: 2px;
                                                      margin-left: 16px;
                                                      font-family: 'Montserrat',
                                                        sans-serif !important;
                                                    "
                                                    >{{ $t('companion') }}</span
                                                  >
                                                  <img
                                                    class="transx_img--bg"
                                                    src="../../../libhome/img/tintuc1.png"
                                                    alt="Featured Image"
                                                  />
                                                  <div
                                                    class="transx_post_cat_cont"
                                                  >
                                                    <div
                                                      class="transx_post_cat_wrapper"
                                                    >
                                                      <a
                                                        href=""
                                                        class="link link_text"
                                                      ></a>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  class="transx_blog_listing_content_wrapper"
                                                >
                                                  <div
                                                    class="transx_blog_listing_meta"
                                                  >
                                                    <span
                                                      class="transx_blog_listing_date"
                                                      >{{ $t('day0') }}</span
                                                    >
                                                    <span
                                                      class="transx_separator"
                                                      >/</span
                                                    >
                                                    <span
                                                      class="transx_blog_listing_author"
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? 'by'
                                                          : 'bởi'
                                                      }}
                                                      Vimc Lines</span
                                                    >
                                                  </div>
                                                  <h3
                                                    class="transx_blog_listing_title"
                                                  >
                                                    <a href="">
                                                      {{ $t('blogtTitle0') }}</a
                                                    >
                                                  </h3>
                                                  <p
                                                    class="transx_blog_listing_excerpt"
                                                    style="text-align: justify"
                                                  >
                                                    {{
                                                      this.$i18n.locale === 'en'
                                                        ? `VIMC LINES is a subsidiary of Vietnam Maritime Corporation (VIMC) with the mission to become a key player in the container shipping industry. VIMC LINES aims to be Vietnam's leading container shipping company, offering high-quality services at competitive prices to meet the growing demands of the market. VIMC CONTAINER LINES JOINT STOCK COMPANY - VIMC LINES will focus on enhancing the quality of container transport services and leading the way in international integration and cooperation within the global maritime transport industry.`
                                                        : 'VIMC LINES là doanh nghiệp thành viên trực thuộc Tổng công ty Hàng hải Việt Nam (VIMC) với sứ mệnh trở thành một trong những doanh nghiệp chủ chốt trong lĩnh vực khai thác tàu Container. VIMC LINES đặt mục tiêu trở thành doanh nghiệp vận tải container hàng đầu Việt Nam, cung cấp dịch vụ chất lượng cao, giá cả cạnh tranh, đáp ứng nhu cầu ngày càng cao của thị trường. CTCP Vận tải Container VIMC - VIMC LINES sẽ tập trung nâng cao chất lượng dịch vụ của thị trường vận tải container và đi đầu trong việc hội nhập quốc tế, hợp tác quốc tế trong thị trường vận tải biển toàn cầu...'
                                                    }}
                                                  </p>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc2.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc3.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc4.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc5.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc6.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc7.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc8.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc9.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc10.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc11.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc12.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc13.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc14.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc15.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc16.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc17.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc18.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc19.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc20.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc21.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc22.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc23.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc24.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc25.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc26.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      src="../../../libhome/img/tintuc27.png"
                                                      alt=""
                                                      style="
                                                        width: 650px;
                                                        object-fit: contain;
                                                      "
                                                    />
                                                    <span
                                                      style="
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        font-size: 12px;
                                                        font-style: italic;
                                                        margin-top: 10px;
                                                      "
                                                      >{{
                                                        this.$i18n.locale ===
                                                        'en'
                                                          ? ` `
                                                          : ' '
                                                      }}
                                                    </span>
                                                  </div>
                                                  <hr />
                                                  <div
                                                    style="
                                                      display: flex;
                                                      align-items: center;
                                                      justify-content: space-between;
                                                    "
                                                  >
                                                    <div>
                                                      <span
                                                        style="
                                                          margin-right: 5px;
                                                          font-weight: 600;
                                                        "
                                                        >#{{
                                                          $t('companion')
                                                        }}</span
                                                      >
                                                    </div>
                                                    <div class="social-buttons">
                                                      <a
                                                        class="btn"
                                                        href="mailto:vimclines@vimc-lines.com"
                                                        ><img
                                                          src="../../../libhome/img/gmail1.svg"
                                                          alt=""
                                                          width="20px"
                                                      /></a>
                                                      <a
                                                        class="btn"
                                                        href="https://wa.me/84335299516"
                                                        target="_blank"
                                                        ><img
                                                          src="../../../libhome/img/whatsapp1.svg"
                                                          alt=""
                                                          width="20px"
                                                      /></a>
                                                      <!-- <a
                                                        class="btn"
                                                        href="#"
                                                        ><img
                                                          src="../../../libhome/img/wechat1.svg"
                                                          alt=""
                                                          width="26px"
                                                      /></a> -->
                                                      <a
                                                        class="btn"
                                                        href="skype:suoinho_mk?chat"
                                                        ><img
                                                          src="../../../libhome/img/skype1.svg"
                                                          alt=""
                                                          width="20px"
                                                      /></a>
                                                      <a
                                                        class="btn"
                                                        href="https://zalo.me/0974040006"
                                                        ><img
                                                          src="../../../libhome/img/zalo1.svg"
                                                          alt=""
                                                          width="20px"
                                                      /></a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="transx_blog_listing_item"
                                              ></div>
                                            </div>
                                            <div class="transx_pagination">
                                              <nav
                                                class="navigation pagination"
                                                role="navigation"
                                                aria-label="Posts"
                                              ></nav>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                        <div class="transx_content_paging_wrapper"></div>
                      </div>
                      <div
                        class="transx_sidebar custom-padding col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4"
                      >
                        <div
                          id="search-2"
                          class="widget widget_search"
                        >
                          <form
                            name="search_form"
                            class="transx_search_form"
                            id="search-395"
                          >
                            <span
                              class="transx_icon_search"
                              style="display: none"
                            >
                              <img
                                src="../../../assets/icons/icons8-search.svg"
                              /> </span
                            ><input
                              type="text"
                              name="s"
                              value=""
                              :placeholder="
                                this.$i18n.locale === 'en'
                                  ? 'Search by title'
                                  : 'Tìm kiếm theo tiêu đề'
                              "
                              title="Search the site..."
                              class="form__field"
                              style="display: none"
                            />
                            <div class="clear"></div>
                          </form>
                        </div>

                        <div
                          id="categories-2"
                          class="widget widget_categories"
                        >
                          <h6
                            class="widget_title"
                            style="font-weight: bold"
                          >
                            {{ $t('category') }}
                          </h6>
                          <hr />

                          <ul>
                            <li
                              class="cat-item cat-item-28"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/0">{{
                                $t('companionCom')
                              }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-28"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/1">{{
                                $t('establishmentCom')
                              }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-27"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/2">{{ $t('seafarer') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-30"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/3">{{
                                $t('containerTerminal')
                              }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-30"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/4">{{
                                $t('annoucement')
                              }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-30"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/5">{{ $t('signCom') }}</a>
                            </li>
                          </ul>
                        </div>
                        <div
                          id="categories-2"
                          class="widget widget_categories"
                        >
                          <h6
                            class="widget_title"
                            style="font-weight: bold"
                          >
                            {{ $t('archive') }}
                          </h6>
                          <hr />

                          <ul>
                            <li
                              class="cat-item cat-item-28"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/1">{{ $t('day1') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-30"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/3">{{ $t('day3') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-27"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/2">{{ $t('day2') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-27"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/4">{{ $t('day4') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-28"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/0">{{ $t('day0') }}</a>
                            </li>
                            <li
                              class="cat-item cat-item-27"
                              data-count="(1)"
                            >
                              <a href="/blogs/detail/4">{{ $t('day5') }}</a>
                            </li>
                          </ul>
                        </div>
                        <div
                          id="custom_html-2"
                          class="mb-10"
                        >
                          <div class="textwidget custom-html-widget">
                            <div
                              class="transx_contact-trigger transx_contact-trigger--style-2"
                            >
                              <img
                                class="transx_contact-trigger__img"
                                src="https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2024/01/contact_background.png"
                                alt="img"
                              />
                              <h4 class="transx_contact-trigger__title">
                                {{ $t('helper') }}
                              </h4>
                              <p class="transx_contact-trigger__text"></p>
                              <a
                                class="transx_button_contactus"
                                href="/contact"
                              >
                                <span style="display: flex; align-items: center"
                                  >{{ $t('contactUs') }}&nbsp;&nbsp;<a
                                    href="/"
                                    target="_blank"
                                  >
                                    <svg class="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="next-arrow"
                                        class="icon arrow-icon"
                                      >
                                        <path
                                          d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                                          fill="#fff"
                                        ></path>
                                      </svg>
                                    </svg>
                                  </a>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          id="tag_cloud-2"
                          class="widget widget_tag_cloud"
                        >
                          <h6 class="widget_title">{{ $t('tag') }}</h6>
                          <hr />
                          <div class="tagcloud">
                            <a
                              href="/blogs/detail/0"
                              class="tag-cloud-link tag-link-31 tag-link-position-1"
                              aria-label="Business (4 items)"
                              >{{ $t('companion') }}</a
                            >
                            <a
                              href="/blogs/detail/1"
                              class="tag-cloud-link tag-link-31 tag-link-position-1"
                              aria-label="Business (4 items)"
                              >{{ $t('establishment') }}</a
                            >
                            <a
                              href="/blogs/detail/2"
                              class="tag-cloud-link tag-link-32 tag-link-position-2"
                              aria-label="Cargo (4 items)"
                              >{{ $t('seafarer') }}</a
                            >
                            <a
                              href="/blogs/detail/3"
                              class="tag-cloud-link tag-link-34 tag-link-position-3"
                              aria-label="Environment (3 items)"
                              >{{ $t('terminal') }}</a
                            >
                            <a
                              href="/blogs/detail/4"
                              class="tag-cloud-link tag-link-34 tag-link-position-3"
                              aria-label="Environment (3 items)"
                              >{{ $t('annoucement') }}</a
                            >
                            <a
                              href="/blogs/detail/5"
                              class="tag-cloud-link tag-link-34 tag-link-position-3"
                              aria-label="Environment (3 items)"
                              >{{ $t('signCom') }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- About End -->
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
};

('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});

/**
 * header
 */
//  javascript: document.getElementById('search-395').submit();
// window.addEventListener('scroll', function() {
//   const navbar = document.getElementsByClassName('headerN');
//   if (window.scrollY > 0) {
//     navbar.classList.add('scrolled');
//   } else {
//     navbar.classList.remove('scrolled');
//   }
// });
</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}

/* // */
.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}

.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_container {
  width: 60px;
  height: 60px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 19px;
  border: 1px solid #005bac;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_box_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #767f7f;
}

.transx_icon_box_item .transx_info_container .transx_icon_box_socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.transx_icon_box_item.transx_view_type_3
  .transx_info_container
  .transx_icon_box_socials
  li:not(:last-of-type) {
  margin: 0 18px 0 0;
}

.elementor-widget-counter.transx_counter_align_left
  .elementor-counter-number-wrapper,
body
  .elementor-widget-counter.transx_counter_align_right
  .elementor-counter-number-wrapper {
  display: block;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  font-size: 42px;
  line-height: 1.1;
  letter-spacing: -0.05em;
  font-weight: 700;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  color: #005bac;
}

.elementor-widget-counter
  .elementor-counter-number-wrapper
  .elementor-counter-number {
  font-size: 60px;
}

.timeline-wrapper .middle-line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 30%;
  transform: translateY(-50%);
  background: #d9d9d9;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}

.transx_bg_color_alt .transx_sidebar .widget {
  background: #fff;
  padding: 32px 40px 40px;
  margin-bottom: 30px !important;
}
.transx_bg_color_alt .transx_blog_listing_image_container {
  margin-bottom: 0;
}

.transx_blog_listing_image_container {
  margin-bottom: 39px;
  position: relative;
  clip-path: polygon(91.2% 0, 100% 17.7%, 100% 100%, 0 100%, 0 0);
  overflow: hidden;
}
.transx_img--bg,
.elementor img.transx_img--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  font-family: 'object-fit: cover; object-position: top center';
  z-index: -1;
}
.transx_bg_color_alt .transx_sidebar .widget {
  background: #fff;
  padding: 32px 40px 40px;
  margin-bottom: 30px !important;
}
.transx_page_content_wrapper {
  background: #f3f6f5;
}
.transx_sidebar .widget.widget_search .transx_icon_search {
  line-height: 1;
  margin-top: 12px;
  cursor: pointer;
  margin-right: 15px;
  position: absolute;
  width: 16px;
  height: 16px;
  right: 19px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.transx_bg_color_alt .transx_sidebar .widget.widget_search {
  padding: 0 0 0 25px;
}
.transx_bg_color_alt .transx_sidebar .widget.widget_search .form__field {
  color: #000;
  background: #fff;
  border: none;
  width: 100%;
  /* //height: 50px; */
  font-size: 14px;
  line-height: 50px;
  box-shadow: none;
  border-radius: 0;
  transition: all 0.3s;
  outline: 0;
}
.transx_sidebar
  .widget.widget_search
  form
  .footer_widget.widget_search
  form
  .transx_sidebar
  .widget_product_search
  form {
  position: relative;
}

.transx_blog_listing_title {
  line-height: 1;
  letter-spacing: 0;
  margin-bottom: 20px;
}
.transx_post_cat_wrapper {
  height: 306px;
}
.transx_blog_listing_meta {
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.9px;
  font-weight: 600;
  margin-bottom: 11px;
}
.transx_blog_listing_title {
  line-height: 1;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-size: 30px;
}
.transx_blog_listing_title a {
  color: #414a53 !important;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 30px;
  line-height: 30px;
}
.transx_page_content_container .icon {
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  line-height: 1.7;
  font-weight: 400;
  -webkit-hyphens: manual;
  hyphens: manual;
  text-align: left;
  font-size: 22px;
  color: #414a53;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  box-sizing: border-box;
}

.transx_contact-trigger {
  position: relative;
  color: #fff;
  background: #e8734a;
  padding: 32px 29px 43px 29px;
  width: 100%;
  max-width: 370px;
  clip-path: polygon(90% 0, 100% 18%, 100% 100%, 0 100%, 0 0);
}

.transx_contact-trigger__img {
  height: 100px;
  width: auto;
  position: absolute;
  bottom: 0;
  right: 2px;
}
.transx_contact-trigger__title {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 14px;
}
.transx_contact-trigger .transx_button_blog {
  font-size: 13px;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  border-color: #fff;
  padding: 14px 25px 13px 19px;
  margin-top: 28px;
  position: relative;
  z-index: 2;
}
.transx_button_blog {
  display: inline-block;
  text-align: center;
  padding: 13px 40px 14px;
  border: solid 2px;
  border-radius: 0 0 15px 0;
  border-color: #e8734a;
  letter-spacing: 0;
  text-decoration: none;
  transition: all 0.3s;
  color: #414a53 !important;
}
.transx_button_contactus {
  display: inline-block;
  text-align: center;
  padding: 13px 40px 14px;
  border: solid 2px;
  border-radius: 0 0 15px 0;
  border-color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  transition: all 0.3s;
  color: #fff !important;
}

.transx_button_contactus:hover {
  color: #e8734e !important;
  background-color: #fff;
}

.transx_button_contactus:hover svg path {
  fill: #e8734e !important;
}
.transx_bg_color_alt .transx_blog_listing_content_wrapper {
  background: #fff;
  padding: 39px 50px 42px;
}
.transx_bg_color_alt .transx_blog_listing_item:not(:last-of-type) {
  margin-bottom: 30px;
}
.elementor-section-blog {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  display: block;
  box-sizing: border-box;
  position: relative;
  margin-top: -60px;
  margin-bottom: 123px;
  width: 100%;
  padding: 45px 0 0 0;
}
.transx_sidebar .widget.widget_categories ul li {
  padding-left: 13px;
  position: relative;
  margin-bottom: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  font-weight: 500;
  list-style-type: none;
}
.transx_sidebar .widget.widget_archive ul li {
  padding-left: 13px;
  position: relative;
  margin-bottom: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  font-weight: 500;
  list-style-type: none;
}

.transx_sidebar .widget.widget_categories ul li,
.transx_sidebar .widget.widget_archive ul li,
.transx_sidebar .widget.widget_transx_categories_widget ul li,
.transx_sidebar .widget_layered_nav ul li,
.transx_sidebar .widget.widget_recent_comments ul li {
  min-height: 24px;
  clear: both;
  list-style-type: none;
}
.transx_sidebar .widget.widget_categories ul li,
.transx_sidebar .widget.widget_archive ul li,
.transx_sidebar .widget.widget_transx_categories_widget ul li,
.transx_sidebar .widget_layered_nav ul li,
.transx_sidebar .widget.widget_rating_filter ul li {
  text-align: right;
  list-style-type: none;
}
.transx_sidebar .widget.widget_categories ul li a,
.transx_sidebar .widget.widget_archive ul li a,
.transx_sidebar .widget.widget_transx_categories_widget ul li a,
.transx_sidebar .widget_layered_nav ul li a,
.transx_sidebar .widget.widget_rating_filter ul li a .star-rating {
  float: left;
  list-style-type: none;
  margin-left: 10px;
}
transx_sidebar .widget.widget_categories ul li,
.transx_sidebar .widget.widget_transx_categories_widget ul li,
.transx_sidebar .widget.widget_recent_entries ul li,
.transx_sidebar .widget.widget_archive ul li,
.transx_sidebar .widget.widget_pages ul li,
.transx_sidebar .widget.widget_meta ul li,
.transx_sidebar .widget.widget_recent_comments ul li,
.transx_sidebar .widget.widget_product_categories ul li,
.transx_sidebar .widget.widget_layered_nav ul li,
.transx_sidebar .widget.widget_rating_filter ul li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.wp-block-rss li {
  margin-bottom: 16px;
  list-style-type: none;
}

/* Style for the widget title */
.widget_title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.widget_categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget_categories ul li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}

.widget_categories ul li::before {
  content: '';
  width: 0.4em; /* Width of the circle */
  height: 0.4em; /* Height of the circle */
  border: 1px solid #000; /* Border thickness and color */
  border-radius: 50%; /* Make it a circle */
  position: absolute;
  left: 0; /* Position it at the start */
  top: 0.5em; /* Adjust to align with text */
  transition: border-color 0.3s ease; /* Smooth transition for color change */
}

/* Style for the links inside list items */
.widget_categories ul li a {
  color: #000; /* Default text color */
  text-decoration: none; /* Remove underline from links */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Style for the numbers inside list items */
.widget_categories ul li::after {
  content: attr(data-count); /* Use the data-count attribute for the number */
  color: #000; /* Default number color */
  margin-left: 0.5em; /* Space between the text and the number */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Hover effect for list items */
.widget_categories ul li:hover::before {
  border-color: #e8734e; /* Change border color on hover */
}

.widget_categories ul li:hover a,
.widget_categories ul li:hover::after {
  color: #e8734e; /* Change text and number color on hover */
}

.widget_tag_cloud {
  margin-bottom: 20px;
}

.widget_title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tagcloud {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tag-cloud-link {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  color: #e8734e !important;
  text-decoration: none;
  border-radius: 4px;
}

.tag-cloud-link:hover {
  background-color: #e8734e;
  color: #fff !important;
}

.social-buttons {
  text-align: center;
}

.btn1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 5px;
  text-decoration: none;
  color: #e8734e !important;
  transition: all 0.3s ease;
}

.btn1 i {
  font-size: 20px;
}

.btn1:hover {
  color: #fff !important;
  background-color: #e8734e;
}

.blog {
  margin-top: -50px;
}

.custom-padding {
  padding: 0 12px 12px 12px !important; /* Điều chỉnh giá trị padding */
  margin-top: -33px !important;
}

.transx_blog_listing_image_container {
  font-family: 'Montserrat', sans-serif !important;
}
</style>
