<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Booking') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="container-booking-tabs">
        <v-tabs
          v-model="selectedTab"
          background-color="transparent"
          style="margin-bottom: 20px"
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
            class="text-capitalize"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <!-- <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <component :is="item.component" />
          </v-tab-item>
        </v-tabs-items> -->
      </div>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item key="0">
          <div
            class="booking-page"
            id="booking-page"
          >
            <div class="container container-booking">
              <v-row>
                <v-col
                  cols="12"
                  :class="{ 'px-1': $vuetify.breakpoint.mobile }"
                >
                  <div :class="{ 'mt-12': $vuetify.breakpoint.mobile }">
                    <v-row
                      no-gutters
                      class="my-2"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="5"
                        :class="{ 'px-1': $vuetify.breakpoint.mobile }"
                      >
                        <v-row
                          no-gutters
                          align="center"
                        >
                          <v-card
                            rounded="lg"
                            width="100%"
                            style="overflow: hidden"
                          >
                          </v-card>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="7"
                        :class="{ 'px-1': $vuetify.breakpoint.mobile }"
                      >
                      </v-col>
                    </v-row>
                  </div>

                  <v-tabs-items v-model="tab">
                    <v-tab-item key="0">
                      <ContainerTrackingSearch />
                    </v-tab-item>
                    <v-tab-item key="1">
                      <VesselScheduleSearch request-open-in-new-tab />
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
                <v-col
                  cols="7"
                  :class="{ 'px-1': $vuetify.breakpoint.mobile }"
                >
                </v-col>
              </v-row>
            </div>
          </div>
          <div
            class="booking-page-table"
            id="booking-page-table"
          >
            <div class="container container-booking-table">
              <div class="table-container">
                <div
                  v-if="isLoading"
                  class="loading-container"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="50"
                    class="loading-spinner"
                  ></v-progress-circular>
                  <div class="loading-text">
                    <span>Loading</span>
                  </div>
                </div>
                <a-table
                  v-else
                  sticky
                  :columns="columns"
                  :data-source="vesselList"
                  :pagination="{
                    current: currentPage,
                    pageSize: 10,
                    total: totalPage,
                  }"
                  @change="handlePageChange"
                  :scroll="scrollSettings"
                  @rowClick="handleRowClick"
                >
                  <template #bodyCell="{ column }">
                    <template v-if="column.key === 'operation'">
                      <a>action</a>
                    </template>
                  </template>
                  <template #summary>
                    <a-table-summary :fixed="fixedTop ? 'top' : 'bottom'">
                      <a-table-summary-row>
                        <a-table-summary-cell
                          :index="0"
                          :col-span="2"
                        >
                          <a-switch
                            v-model="fixedTop"
                            checked-children="Fixed Top"
                            un-checked-children="Fixed Top"
                          ></a-switch>
                        </a-table-summary-cell>
                        <a-table-summary-cell
                          :index="2"
                          :col-span="8"
                        >
                          Scroll Context
                        </a-table-summary-cell>
                        <a-table-summary-cell :index="10">
                          Fix Right
                        </a-table-summary-cell>
                      </a-table-summary-row>
                    </a-table-summary>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          key="1"
          style="padding-bottom: 80px; transition: all 0.3s ease-in-out"
        >
          <div style="max-width: 1724px; margin-left: 94px">
            <v-card
              class="py-4 pl-7 pr-2"
              rounded="lg"
              style="background-color: #f5f5f5; border: 1px solid #ddd"
            >
              <v-card-text class="search-box">
                <v-form @submit.prevent="onSearch">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 2fr 1fr;
                      grid-gap: 32px;
                    "
                  >
                    <div style="width: 100%; position: relative">
                      <label for="departurePort">Địa điểm</label>
                      <input
                        class="address-warehouse"
                        id="departurePort"
                        type="text"
                        placeholder="Địa điểm"
                        v-model="departurePort"
                        required
                      />
                      <span class="icon-warehouse">
                        <i class="mdi mdi-map-marker"></i>
                      </span>
                    </div>

                    <!-- Date picker remains the same -->
                    <div style="width: 100%; margin: 0 0 0 -10px">
                      <v-menu
                        v-model="dateMenuWarehouse"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <label for="datesWarehouse"
                            >Ngày đi và ngày đến</label
                          >
                          <v-text-field
                            id="datesWarehouse"
                            v-model="formattedDatesWarehouse"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            placeholder="Ngày đi và ngày đến"
                            dense
                            clearable
                            hide-details="auto"
                            prepend-inner-icon="mdi-calendar"
                            class="full-width"
                            style="margin-top: 5px"
                          />
                        </template>
                        <v-date-picker
                          v-model="datesWarehouse"
                          range
                          no-title
                          @change="updateDatesWarehouse"
                        />
                      </v-menu>
                    </div>
                  </div>

                  <!-- Second row -->
                  <v-row
                    class="pa-2"
                    justify="space-between"
                    align="center"
                  >
                    <v-col cols="4">
                      <v-text-field
                        v-model="phone"
                        :label="$t('phone')"
                        outlined
                        clearable
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-phone"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        v-model="email"
                        label="Email"
                        outlined
                        clearable
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-email"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <v-col cols="4">
                      <v-btn
                        block
                        color="#e6734f"
                        style="height: 40px; margin-top: -15px; color: white"
                        @click="onSearch"
                      >
                        {{ $t('Request') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-tab-item>

        <v-tab-item
          key="2"
          style="padding-bottom: 80px; transition: all 0.3s ease-in-out"
        >
          <div style="max-width: 1724px; margin-left: 94px">
            <v-card
              class="py-4 pl-7 pr-2"
              rounded="lg"
              style="background-color: #f5f5f5; border: 1px solid #ddd"
            >
              <v-card-text class="search-box">
                <v-form @submit.prevent="onSearchTruck">
                  <v-row
                    class="pa-2"
                    justify="space-between"
                    align="center"
                  >
                    <!-- Departure Port -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="departurePortTruck"
                        label="Điểm đi"
                        required
                        clearable
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-map-marker"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <!-- Arrival Port -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="arrivalPortTruck"
                        label="Điểm đến"
                        required
                        clearable
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-map-marker"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <!-- Departure and Arrival Dates -->
                    <v-col cols="4">
                      <v-menu
                        v-model="dateMenuTruck"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formattedDatesTruck"
                            label="Ngày đi và ngày đến"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            outlined
                            dense
                            hide-details="auto"
                            prepend-inner-icon="mdi-calendar"
                            style="margin-bottom: 16px"
                          />
                        </template>
                        <v-date-picker
                          v-model="datesTruck"
                          range
                          no-title
                          @change="updateDatesTruck"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row
                    class="pa-2"
                    justify="space-between"
                    align="center"
                  >
                    <!-- Phone Number -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="phoneNumberTruck"
                        label="Số điện thoại"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-ferry"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <!-- Email -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="emailTruck"
                        label="Email"
                        outlined
                        dense
                        hide-details="auto"
                        prepend-inner-icon="mdi-ferry"
                        style="margin-bottom: 16px"
                      />
                    </v-col>

                    <!-- Request Button -->
                    <v-col cols="4">
                      <v-btn
                        block
                        color="#e6734f"
                        style="height: 40px; margin-top: -15px; color: white"
                        @click="onSearchTruck"
                      >
                        Request
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card
            class="py-4 pl-7 pr-2"
            rounded="lg"
          >
            <v-card-subtitle class="subheading">
              <span style="color: red">*</span>
              {{ $t('label-tracking-container') }}
              <span
                class="booking-label"
                :style="{ marginLeft: computedMargin }"
              >
                {{ $t('label-tracking-booking') }}
              </span>
            </v-card-subtitle>
            <v-card-text class="search-box">
              <v-form
                @submit="onTrackingContainer"
                onSubmit="return false;"
              >
                <v-row align="center">
                  <v-autocomplete
                    id="container-autocomplete-field"
                    class="mr-4 custom-select-field fixed-width"
                    v-model="containerNo"
                  />
                  <v-select
                    id="booking-select-field"
                    class="mr-4 custom-select-field fixed-width"
                    v-model="bookingNo"
                  />
                  <v-btn
                    style="
                      text-transform: none;
                      font-size: 16px;
                      color: #fff !important;
                      background-color: #e6734f !important;
                    "
                    class="px-10 py-6"
                    large
                  >
                    <v-icon> mdi-magnify </v-icon>
                    {{ $t('search') }}
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';
import moment from 'moment';
import vesselScheduleApi from '@/api/vsl/vessel-schedule.api';

export default {
  name: 'Booking',
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data() {
    return {
      tab: 1,
      selectedTab: 0,
      items: [
        { tab: 'Booking container', component: 'VesselScheduleSearch' },
        { tab: 'Warehouse', component: 'ContainerTrackingSearch' },
        { tab: 'Trucking', component: 'TruckingSearch' },
      ],
      containerOptions: [], // Danh sách container gốc
      bookingOptions: [], // Danh sách booking gốc
      filteredContainerOptions: [], // Danh sách container đã lọc
      filteredBookingOptions: [], // Danh sách booking đã lọc
      departurePort: '',
      dateMenuWarehouse: false,
      datesWarehouse: [],
      formattedDatesWarehouse: '',
      phone: '',
      email: '',
      departurePortTruck: '',
      arrivalPortTruck: '',
      dateMenuTruck: false,
      datesTruck: [],
      formattedDatesTruck: '',
      phoneNumberTruck: '',
      emailTruck: '',
      isToastVisible: false,
      dialog: {
        result: false,
      },
      searchMenuTabs: [
        {
          key: 0,
          title: 'Tracking',
          icon: 'mdi-cube-scan',
          color: 'brown',
          colorModifier: 'lighten-5',
          colorModifierActive: 'lighten-3',
        },
        {
          key: 1,
          title: 'Booking',
          icon: 'mdi-focus-field',
          color: 'light-blue',
          colorModifier: 'lighten-5',
          colorModifierActive: 'lighten-3',
        },
        // {
        //   key: 2,
        //   title: 'Debit',
        //   icon: 'mdi-file-document-multiple-outline',
        //   color: 'green',
        //   colorModifier: 'lighten-5',
        //   colorModifierActive: 'lighten-3',
        // },
      ],
      columns: [
        {
          title: 'Mã chuyến',
          width: 100,
          dataIndex: 'voyage.code',
          key: 'voyage.code',
          className: 'align-center',
          fixed: 'left',
        },
        { title: 'Tàu', dataIndex: 'vessel.name', key: '1', width: 200 },
        { title: 'ETD', dataIndex: 'etd', key: '2', width: 100 },
        { title: 'ETA', dataIndex: 'eta', key: '3', width: 100 },
        {
          title: 'POL',
          dataIndex: 'pol_terminal.port.name',
          key: '4',
          width: 250,
        },
        {
          title: 'POL Terminal',
          dataIndex: 'pol_terminal.name',
          key: '5',
          width: 250,
        },
        {
          title: 'POD',
          dataIndex: 'pod_terminal.port.name',
          key: '6',
          width: 250,
        },
        {
          title: 'POD Terminal',
          dataIndex: 'pod_terminal.name',
          key: '7',
          width: 250,
        },
      ],
      vesselList: null,
      isLoading: true,
      fixedTop: false,
      scrollSettings: {}, // Mặc định không có thanh cuộn
      currentPage: 1,
      totalPage: null,
    };
  },
  watch: {
    datesWarehouse(newDates) {
      if (newDates && newDates.length === 2) {
        const startDate = moment(newDates[0]);
        const endDate = moment(newDates[1]);
        if (startDate.isBefore(endDate)) {
          this.formattedDatesWarehouse = `${startDate.format(
            'DD/MM/YYYY'
          )} - ${endDate.format('DD/MM/YYYY')}`;
        } else {
          this.showToast('Ngày bắt đầu phải nhỏ hơn ngày kết thúc', 'error');
          this.formattedDatesWarehouse = '';
        }
      }
    },
    datesTruck(newDates) {
      if (newDates && newDates.length === 2) {
        const startDate = moment(newDates[0]);
        const endDate = moment(newDates[1]);
        if (startDate.isBefore(endDate)) {
          this.formattedDatesTruck = `${startDate.format(
            'DD/MM/YYYY'
          )} - ${endDate.format('DD/MM/YYYY')}`;
        } else {
          this.showToast('Ngày bắt đầu phải nhỏ hơn ngày kết thúc', 'error');
          this.formattedDatesTruck = '';
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 100) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thay đổi thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) {
            requestAnimationFrame(scroll);
          }
        };

        requestAnimationFrame(scroll);
      };

      const bookingPageElement = document.getElementById(
        'carousel-item-caption'
      );
      if (bookingPageElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(bookingPageElement);
      }
    });
    this.fetchAndUpdateBookingData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Kiểm tra kích thước ban đầu khi trang được tải
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {},
  methods: {
    ...mapMutations('vsl/vesselSchedule', ['RESET']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
    showToast(message, type) {
      if (this.isToastVisible) return;
      this.isToastVisible = true;

      if (type === 'error') {
        this.$toast.error(message);
      } else {
        this.$toast.success(message);
      }

      setTimeout(() => {
        this.isToastVisible = false;
      }, 4000);
    },
    updateDatesWarehouse(date) {
      this.datesWarehouse = date;
      this.dateMenuWarehouse = false;
    },
    updateDatesTruck(dates) {
      this.datesTruck = dates;
      if (dates && dates.length === 2) {
        this.formattedDatesTruck = `${moment(dates[0]).format(
          'DD/MM/YYYY'
        )} - ${moment(dates[1]).format('DD/MM/YYYY')}`;
        this.dateMenuTruck = false;
      }
    },
    async onSearch() {
      if (!this.departurePort) {
        return this.showToast('Bạn Chưa nhập địa điểm', 'error');
      }
      if (!this.datesWarehouse || this.datesWarehouse.length < 2) {
        return this.showToast(
          'Bạn Chưa nhập ngày bắt đầu hoặc ngày kết thúc',
          'error'
        );
      }
      if (!this.phone) {
        return this.showToast('Bạn Chưa nhập số điện thoại', 'error');
      }
      if (!this.email) {
        return this.showToast('Bạn Chưa nhập email', 'error');
      }

      const searchParams = {
        address: this.departurePort,
        departure_date: this.datesWarehouse[0],
        arrival_date: this.datesWarehouse[1],
        phone: this.phone,
        email: this.email,
      };

      try {
        await vesselScheduleApi.sendStaticEmail('warehouse', searchParams);
        this.showToast('Gửi mail thành công !', 'success');
        this.clearForm();
      } catch (error) {
        console.error('Error during search:', error);
      }
    },
    async onSearchTruck() {
      if (!this.departurePortTruck) {
        return this.showToast('Bạn Chưa nhập điểm đi', 'error');
      }
      if (!this.arrivalPortTruck) {
        return this.showToast('Bạn Chưa nhập điểm đến', 'error');
      }
      if (!this.datesTruck || this.datesTruck.length < 2) {
        return this.showToast('Bạn Chưa nhập ngày đi và ngày đến', 'error');
      }
      if (!this.phoneNumberTruck) {
        return this.showToast('Bạn Chưa nhập số điện thoại', 'error');
      }
      if (!this.emailTruck) {
        return this.showToast('Bạn Chưa nhập email', 'error');
      }

      const searchParams = {
        depart_address: this.departurePortTruck,
        arrive_address: this.arrivalPortTruck,
        departure_date: this.datesTruck[0],
        arrival_date: this.datesTruck[1],
        phone: this.phoneNumberTruck,
        email: this.emailTruck,
      };

      try {
        await vesselScheduleApi.sendStaticEmail('trucking', searchParams);
        this.showToast('Gửi mail thành công !', 'success');
        this.clearFormTruck();
      } catch (error) {
        console.error('Error during search:', error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchAndUpdateBookingData() {
      this.isLoading = true;

      try {
        const queryParams = { page: this.currentPage - 1, size: 10 };
        const res = await VesselScheduleApi.fetchVesselScheduleList(
          queryParams
        );
        this.vesselList = res.data.data || [];
        this.totalPage = res.data.total;
        this.vesselList = this.vesselList?.map((item) => {
          return {
            ...item,
            etd: this.formatDate(item.etd),
            eta: this.formatDate(item.eta),
          };
        });
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.departurePort = '';
      this.datesWarehouse = [];
      this.formattedDatesWarehouse = '';
      this.phone = '';
      this.email = '';
    },
    clearFormTruck() {
      this.departurePortTruck = '';
      this.arrivalPortTruck = '';
      this.datesTruck = [];
      this.formattedDatesTruck = '';
      this.phoneNumberTruck = '';
      this.emailTruck = '';
    },
    handleRowClick(record) {
      this.$router.push(`/booking/detail/${record.code}`);
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      // Khi màn hình nhỏ hơn 1367px, bật thanh cuộn
      if (screenWidth < 1367) {
        this.scrollSettings = { x: 1000 }; // Đặt chiều ngang cuộn
      } else {
        this.scrollSettings = {}; // Không cuộn nếu màn hình lớn hơn
      }
    },
    handlePageChange(pagination) {
      this.currentPage = pagination.current;
      this.fetchAndUpdateBookingData();
    },
  },
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
    // if (debit_search_no){
    //   this.tab = 2;
    // }
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
  .ant-table-thead > tr > th {
    background-color: #e8724a !important; /* Màu nền bạn muốn */
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-left: 1px solid #ffffff !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff !important;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #000000 !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:hover {
    background: #f2f2f2 !important;
  }
  .align-center {
    text-align: center !important;
    padding: 0 !important;
  }
  .v-input__slot {
    width: 530px !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
}

#booking-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
}

#booking-page-table {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 80px;
}

.container-booking {
  max-width: 1734px !important;
  margin-bottom: -35px;
  margin-left: 94px;
}
.container-booking-tabs {
  max-width: 1734px !important;
  margin-left: 104px;
}

.container-booking-table {
  max-width: 1704px !important;
  margin-bottom: -35px;
  margin-top: -60px;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item a) {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item:hover) {
  border-color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item a:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item-active) {
  border-color: #e8724a !important;
  font-weight: 500 !important;
}

:deep(.ant-pagination-item-active a) {
  color: #e8724a !important;
}

:deep(.ant-pagination-item-link:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border-color: #e8724a !important;
}

:deep(.ant-pagination-item-link) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border: none !important;
}

:deep(.ant-pagination-prev a) {
  color: #000000 !important;
}

:deep(.ant-pagination-next a) {
  color: #000000 !important;
}

:deep(.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

:deep(.ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  margin-bottom: 10px;
}

.loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.table-container .ant-table-body {
  overflow-x: hidden; /* Mặc định không cuộn */
}

@media screen and (max-width: 1367px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  .container-booking {
    max-width: 1734px !important;
    margin-bottom: -20px;
    margin-left: 12px;
  }
  :deep(.v-input__slot) {
    width: 375px !important;
  }
}

@media screen and (max-width: 1181px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 915px !important;
  }
}

@media screen and (max-width: 1025px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 680px !important;
  }
}

.address-warehouse {
  border: 1px solid #989898;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 15px;
  outline: none;
  padding-left: 35px;
}

/* Nếu vẫn không hoạt động, thử thêm !important và tăng độ ưu tiên */
input.address-warehouse:hover,
input.address-warehouse:focus,
input.address-warehouse:active {
  border: 2px solid #1976d2 !important;
  border-color: #1976d2 !important;
}

.icon-warehouse {
  position: absolute;
  top: 62%;
  left: 10px;
  font-size: 25px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #707070;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-col-8 {
  flex: 2;
}
.v-input__slot {
  width: 900px !important;
}

.form-col-4 {
  flex: 1;
}

.full-width {
  width: 100%;
}

.v-card {
  transition: margin-bottom 0.3s ease-in-out;
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
