<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Booking') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="booking-page"
      id="booking-page"
    >
      <div class="container container-booking">
        <v-row>
          <v-col
            cols="12"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
            <div>
              <div
                :class="{
                  'mt-12': $vuetify.breakpoint.mobile,
                }"
              >
                <v-row
                  no-gutters
                  class="my-2"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="5"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-card
                        rounded="lg"
                        width="100%"
                        style="overflow: hidden"
                      >
                        <!-- <v-tabs
                          v-model="tab"
                          :active-class="searchMenuTabs[tab].color"
                          height="40px"
                          grow
                        >
                          <v-tabs-slider
                            :color="`${searchMenuTabs[tab].color}`"
                          ></v-tabs-slider>
                          <v-tab
                            v-for="item in searchMenuTabs"
                            :key="item.key"
                            :active-class="`${item.color} ${item.colorModifier} ${item.color}--text`"
                            :ripple="false"
                          >
                            <v-icon
                              left
                              v-text="item.icon"
                              :color="item.color"
                            >
                            </v-icon>
                            {{ item.title }}
                          </v-tab>
                        </v-tabs> -->
                      </v-card>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="7"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                  </v-col>
                </v-row>
              </div>

              <v-tabs-items v-model="tab">
                <v-tab-item key="0">
                  <ContainerTrackingSearch />
                </v-tab-item>
                <v-tab-item key="1">
                  <VesselScheduleSearch request-open-in-new-tab />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
          <v-col
            cols="7"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
          </v-col>
        </v-row>
      </div>
    </div>
    <v-app>
      <v-main class="grey lighten-4">
        <v-container
          fluid
          class="pa-6"
        >
          <!-- Search Bar -->
          <v-card class="mb-6 search-bar">
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="3"
                  class="pr-1"
                >
                  <v-text-field
                    label="Port Kelang"
                    prepend-inner-icon="mdi-anchor"
                    solo
                    dense
                    hide-details
                    class="search-field"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="px-1"
                >
                  <v-text-field
                    label="Calcutta"
                    prepend-inner-icon="mdi-map-marker"
                    solo
                    dense
                    hide-details
                    class="search-field"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="px-1"
                >
                  <v-text-field
                    label="02 Dec, 2024"
                    prepend-inner-icon="mdi-calendar"
                    solo
                    dense
                    hide-details
                    class="search-field"
                  ></v-text-field>
                </v-col>
                <!-- <v-col
                  cols="12"
                  sm="2"
                  class="px-1"
                >
                  <v-select
                    label="FCL, 20' ST"
                    prepend-inner-icon="mdi-package-variant-closed"
                    solo
                    dense
                    hide-details
                    class="search-field"
                  ></v-select>
                </v-col> -->
                <v-col
                  cols="12"
                  sm="1"
                  class="pl-1"
                >
                  <v-btn
                    color="primary"
                    icon
                    large
                    class="search-button"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row>
            <!-- Filters -->
            <v-col
              cols="12"
              md="3"
            >
              <v-card class="filter-card">
                <v-card-title class="filter-title">
                  Filter
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    small
                    color="primary"
                    class="clear-button"
                    >Clear</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-subheader class="filter-subheader"
                    >Included services</v-subheader
                  >
                  <v-checkbox
                    label="Ocean freight"
                    hide-details
                    class="filter-checkbox"
                  ></v-checkbox>
                  <v-checkbox
                    label="Place of loading"
                    hide-details
                    class="filter-checkbox"
                  ></v-checkbox>
                  <v-checkbox
                    label="Port of origin"
                    hide-details
                    class="filter-checkbox"
                  ></v-checkbox>

                  <v-subheader class="filter-subheader">Price</v-subheader>
                  <!-- <v-range-slider
                  v-model="priceRange"
                  min="2288"
                  max="3396"
                  hide-details
                  class="filter-slider"
                ></v-range-slider> -->
                  <div class="d-flex align-center">
                    <v-icon
                      small
                      color="blue"
                      >mdi-circle</v-icon
                    >
                    <v-divider
                      style="
                        flex-grow: 1;
                        height: 3px;
                        width: 100% !important;
                        background-color: #1976d2;
                      "
                    ></v-divider>
                    <v-icon
                      small
                      color="blue"
                      >mdi-circle</v-icon
                    >
                  </div>
                  <div class="d-flex justify-space-between slider-labels">
                    <span>2288</span>
                    <span>3396</span>
                  </div>

                  <v-subheader class="filter-subheader"
                    >Transit time</v-subheader
                  >
                  <!-- <v-range-slider
                  v-model="transitRange"
                  min="29"
                  max="35"
                  hide-details
                  class="filter-slider"
                  style="width: 200px !important"
                ></v-range-slider> -->
                  <div class="d-flex align-center">
                    <v-icon
                      small
                      color="blue"
                      >mdi-circle</v-icon
                    >
                    <v-divider
                      style="
                        flex-grow: 1;
                        height: 3px;
                        width: 100% !important;
                        background-color: #1976d2;
                      "
                    ></v-divider>
                    <v-icon
                      small
                      color="blue"
                      >mdi-circle</v-icon
                    >
                  </div>
                  <div class="d-flex justify-space-between slider-labels">
                    <span>29 days</span>
                    <span>35 days</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Main Content -->
            <v-col
              cols="12"
              md="9"
            >
              <v-card class="mb-6 sort-card">
                <v-card-text class="d-flex justify-space-between align-center">
                  <div class="disclaimer">
                    <v-icon
                      small
                      color="primary"
                      class="mr-1"
                      >mdi-information</v-icon
                    >
                    <span class="subtitle-2">Disclaimer</span>
                  </div>
                  <!-- <v-select
                    label="Sort by"
                    :items="['Recommended']"
                    dense
                    outlined
                    hide-details
                    class="sort-select"
                    style="border: 1px solid white !important"
                  ></v-select> -->
                  <div
                    class="d-flex align-center"
                    style="height: 48px"
                  >
                    <v-btn
                      text
                      small
                      color="primary"
                      class="sort-button mr-2"
                      style="text-transform: capitalize"
                    >
                      Sort by
                    </v-btn>
                    <v-btn
                      text
                      small
                      color="primary"
                      class="recommend-button"
                      style="text-transform: capitalize"
                    >
                      Recommended
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>

              <v-alert
                type="warning"
                colored-border
                border="left"
                class="mb-6 custom-alert"
              >
                There are no valid rates for this route now. For indication,
                please see past rates for the last 3 months and please request a
                quote.
              </v-alert>

              <!-- Route Cards -->
              <v-card
                v-if="vesselDetails"
                :key="i"
                class="mb-6 route-card"
              >
                <v-card-text>
                  <v-row
                    align="center"
                    no-gutters
                  >
                    <v-col cols="6">
                      <div class="d-flex align-center">
                        <v-avatar
                          size="40"
                          color="grey lighten-2"
                          class="mr-3"
                        >
                          <v-icon medium>mdi-ferry</v-icon>
                        </v-avatar>
                        <div>
                          <div class="subtitle-1 font-weight-medium">
                            {{ vesselDetails.vessel.name }}
                          </div>
                          <div class="caption red--text">Expired</div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="3"
                      class="text-center"
                    >
                      <v-icon
                        small
                        class="mr-1"
                        >mdi-package-variant</v-icon
                      >
                      <span class="caption">20'ST</span>
                    </v-col>
                    <v-col
                      cols="3"
                      class="text-center"
                    >
                      <v-icon
                        small
                        class="mr-1"
                        >mdi-clock-outline</v-icon
                      >
                      <span class="caption">
                        {{
                          calculateDaysBetween(
                            vesselDetails.eta,
                            vesselDetails.etd
                          )
                        }}
                        days</span
                      >
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row
                    align="center"
                    no-gutters
                  >
                    <v-col cols="6">
                      <div class="caption mb-1">
                        <v-icon
                          small
                          class="mr-1"
                          >mdi-anchor</v-icon
                        >
                        {{ vesselDetails.pol_terminal.name }}
                      </div>
                      <div class="caption">
                        <v-icon
                          small
                          class="mr-1"
                          >mdi-map-marker</v-icon
                        >
                        {{ vesselDetails.pod_terminal.name }}
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="subtitle-1 font-weight-bold mb-1">
                        USD 2,288
                      </div>
                      <v-btn
                        color="primary"
                        class="request-button"
                        @click="showQuotationDetail"
                        >Request</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Quick Request Card -->
              <v-card class="quick-request-card">
                <v-card-text>
                  <v-row
                    align="center"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      sm="8"
                    >
                      <div class="d-flex align-center">
                        <v-icon
                          color="primary"
                          size="36"
                          class="mr-3"
                          >mdi-credit-card</v-icon
                        >
                        <div>
                          <div class="subtitle-1 font-weight-bold">
                            Need more options? Quick request
                          </div>
                          <div class="caption">
                            Fill up the short form so that our team can contact
                            you and provide an individual offer.
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      class="text-right"
                    >
                      <v-btn
                        color="primary"
                        class="request-button"
                        >Make a request</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';
import dayjs from 'dayjs';

export default {
  name: 'BookingDetailPage',
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data() {
    return {
      tab: 1,
      isLoading: true,
      fixedTop: false,
      scrollSettings: {}, // Mặc định không có thanh cuộn
      id: null,
      vesselDetails: null,
    };
  },
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 100) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thay đổi thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) {
            requestAnimationFrame(scroll);
          }
        };

        requestAnimationFrame(scroll);
      };

      const bookingPageElement = document.getElementById(
        'carousel-item-caption'
      );
      if (bookingPageElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(bookingPageElement);
      }
    });

    // Gọi API khi trang tải lần đầu tiên
    this.fetchGetDetailVessel();

    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Kiểm tra kích thước ban đầu khi trang được tải
  },

  watch: {
    '$route.params.id': {
      handler(newVal) {
        if (newVal) {
          this.fetchGetDetailVessel();
        }
      },
      immediate: true, // Gọi ngay khi mounted() nếu đã có giá trị
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {},
  methods: {
    ...mapMutations('vsl/vesselSchedule', ['RESET']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
    showQuotationDetail() {
      this.$router.push(`/booking/detail/1/${this.vesselDetails.code}`);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchGetDetailVessel() {
      this.isLoading = true;
      try {
        this.id = this.$route.params.id;
        const res = await VesselScheduleApi.getVesselSchedule({
          code: this.id,
        });
        this.vesselDetails = res.data.data;
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        this.isLoading = false;
      }
    },

    handleResize() {
      const screenWidth = window.innerWidth;
      // Khi màn hình nhỏ hơn 1367px, bật thanh cuộn
      if (screenWidth < 1367) {
        this.scrollSettings = { x: 1000 }; // Đặt chiều ngang cuộn
      } else {
        this.scrollSettings = {}; // Không cuộn nếu màn hình lớn hơn
      }
    },

    // trừ ngày
    calculateDaysBetween(eta, etd) {
      // Dùng dayjs để chuyển đổi chuỗi ngày thành đối tượng dayjs và tính số ngày
      const etaDate = dayjs(eta);
      const etdDate = dayjs(etd);

      // Tính số ngày giữa eta và etd
      return etaDate.diff(etdDate, 'day');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
  .ant-table-thead > tr > th {
    background-color: #e8724a !important; /* Màu nền bạn muốn */
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-left: 1px solid #ffffff !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff !important;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #000000 !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:hover {
    background: #f2f2f2 !important;
  }
  .align-center {
    text-align: center !important;
    padding: 0 !important;
  }
  .v-input__slot {
    width: 530px !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
}

#booking-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
}

#booking-page-table {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 80px;
}

.container-booking {
  max-width: 1734px !important;
  margin-bottom: -35px;
  margin-left: 94px;
}

.container-booking-table {
  max-width: 1704px !important;
  margin-bottom: -35px;
  margin-top: -60px;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item a) {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item:hover) {
  border-color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item a:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item-active) {
  border-color: #e8724a !important;
  font-weight: 500 !important;
}

:deep(.ant-pagination-item-active a) {
  color: #e8724a !important;
}

:deep(.ant-pagination-item-link:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border-color: #e8724a !important;
}

:deep(.ant-pagination-item-link) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border: none !important;
}

:deep(.ant-pagination-prev a) {
  color: #000000 !important;
}

:deep(.ant-pagination-next a) {
  color: #000000 !important;
}

:deep(.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

:deep(.ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon) {
  color: red !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  margin-bottom: 10px;
}

.loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.table-container .ant-table-body {
  overflow-x: hidden; /* Mặc định không cuộn */
}

@media screen and (max-width: 1367px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  .container-booking {
    max-width: 1734px !important;
    margin-bottom: -20px;
    margin-left: 12px;
  }
  :deep(.v-input__slot) {
    width: 375px !important;
  }
}
.search-bar {
  border-radius: 8px;
}

.search-field {
  margin-right: 8px;
}

.search-button {
  height: 40px;
  width: 40px;
}

.filter-card {
  border-radius: 8px;
}

.filter-title {
  font-size: 18px;
  font-weight: 500;
}

.clear-button {
  font-size: 14px;
}

.filter-subheader {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.filter-checkbox {
  margin-top: 4px;
  margin-bottom: 4px;
}

.filter-slider {
  margin-top: 16px;
  width: 200px !important;
}

.slider-labels {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
}

.sort-card {
  border-radius: 8px;
}

.disclaimer {
  font-size: 14px;
  margin: 0 0 0 20px;
}

.sort-select {
  max-width: 600px;
  border: none;
}

.custom-alert {
  border-left-width: 4px !important;
  border-color: #fb8c00 !important;
  background-color: #fff3e0 !important;
  color: #e65100 !important;
}

.route-card {
  border-radius: 8px;
}

.request-button {
  text-transform: none;
}

.quick-request-card {
  border-radius: 8px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 1181px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 915px !important;
  }
}

@media screen and (max-width: 1025px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 680px !important;
  }
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
