<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="contact" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/vimc diamond2.png"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('contactUs') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="location location-contact">
      <div class="container container-call">
        <div class="row">
          <div class="col-lg-3">
            <div
              style="
                text-align: center;
                margin-bottom: 20px;
                color: #e8734a;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
              "
            >
              Đánh giá chất lượng dịch vụ
            </div>
            <div
              style="
                text-align: center;
                /* margin-bottom: 20px; */
                color: #000;
                font-size: 24px;
                font-weight: 600;
                text-decoration: underline;
              "
            >
              <a
                style="color: #000 !important; text-decoration: none"
                href="http://khaosat.vimc.co"
                target="_blank"
              >
                http://khaosat.vimc.co
              </a>
            </div>
            <div
              style="
                text-align: center;
                color: #e8734a;
                font-size: 24px;
                font-weight: 600;
              "
            >
              <img
                src="../../../libhome/img/survey.jpg"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="section-header section-header-call text-left"
              id="contact-1"
            >
              <p>{{ $t('callUsNow') }}</p>
              <h2>02435770894</h2>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>{{ $t('haiphong') }}</h3>
                    <p>{{ $t('locaHP') }}</p>
                    <p>
                      <strong>{{ $t('call') }}:</strong>090 4116369
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>{{ $t('hochiminh') }}</h3>
                    <p>{{ $t('locaHCM') }}</p>
                    <p>
                      <strong>{{ $t('call') }}:</strong>+84.974.040.006
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>{{ $t('danang') }}</h3>
                    <p>{{ $t('locaDN') }}</p>
                    <p>
                      <strong>{{ $t('call') }}:</strong>+84-236-3823538
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>{{ $t('quynhon') }}</h3>
                    <p>
                      {{ $t('locaQN') }}
                    </p>
                    <p>
                      <strong>{{ $t('call') }}:</strong>+84.256.382.2687
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>CHENNAI - AGENT</h3>
                    <p>
                      {{ $t('chennai') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>PORT KLANG - AGENT</h3>
                    <p>
                      {{ $t('klang') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="location-item">
                  <i class="fa fa-map-marker-alt"></i>
                  <div class="location-text">
                    <h3>Kolkata, India - AGENT</h3>
                    <p style="text-align: justify">
                      Seahorse Ship Agencies Pvt. Ltd. Hastings Chambers, 4th
                      Floor, 7C, Kiron Shankar Roy Road, Kolkata-700001.India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="location-form">
              <h3>{{ $t('getInTouch') }}</h3>
              <form
                ref="form"
                @submit.prevent="sendEmail"
              >
                <div class="control-group">
                  <input
                    v-model="name"
                    type="text"
                    class="form-control"
                    :placeholder="$i18n.locale === 'en' ? 'Name' : 'Tên'"
                    required
                  />
                </div>
                <div class="control-group">
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="control-group">
                  <textarea
                    v-model="message"
                    class="form-control"
                    :placeholder="
                      $i18n.locale === 'en' ? 'Description' : 'Mô tả'
                    "
                    required
                  ></textarea>
                </div>
                <div
                  class="call-footer"
                  style="display: flex; justify-content: center"
                >
                  <div class="btn-basic">
                    <input
                      type="submit"
                      :value="
                        $i18n.locale === 'en' ? 'Send Request' : 'Gửi yêu cầu'
                      "
                      :disabled="isLoading || isButtonDisabled"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->
    <div class="container container-contact">
      <div class="row transx_sidebar_none transx_bg_color_default">
        <div class="col-lg-12 col-xl-12 col-p">
          <div class="transx_content_wrapper">
            <div
              data-elementor-type="wp-page"
              data-elementor-id="42"
              class="elementor elementor-42"
              data-elementor-settings="[]"
            >
              <div class="elementor-section-wrap">
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="223bd736"
                  data-element_type="section"
                  style="padding: 137px 0px 140px 0px"
                >
                  <div class="elementor-background-overlay"></div>
                  <v-row class="">
                    <v-col
                      class="col-5 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7b9387f9 elementor-left"
                      data-id="7b9387f9"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-346ab512 elementor-widget elementor-widget-transx_heading"
                          data-id="346ab512"
                          data-element_type="widget"
                          data-widget_type="transx_heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_heading_widget">
                              <div
                                class="transx_up_heading"
                                style="fontsize: 16px"
                                id="contact-2"
                              >
                                {{ $t('contactUs') }}
                              </div>
                              <h2 class="transx_heading">
                                {{ $t('contactInfo') }}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ce65c13 elementor-widget elementor-widget-transx_icon_box"
                          data-id="ce65c13"
                          data-element_type="widget"
                          data-widget_type="transx_icon_box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_icon_box_widget">
                              <div
                                class="transx_icon_box_item transx_view_type_3"
                              >
                                <div
                                  class="transx_icon_container"
                                  style="border: 1px solid #e8734a"
                                >
                                  <svg class="icon">
                                    <svg
                                      viewBox="0 0 384 384"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M353.188 252.052c-23.51 0-46.594-3.677-68.469-10.906-10.906-3.719-23.323-.833-30.438 6.417l-43.177 32.594c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208 11.167-20.198 7.635-31.448-7.26-21.99-10.948-45.063-10.948-68.583C132.146 13.823 118.323 0 101.333 0H30.812C13.823 0 0 13.823 0 30.812 0 225.563 158.438 384 353.188 384c16.99 0 30.813-13.823 30.813-30.813v-70.323c-.001-16.989-13.824-30.812-30.813-30.812zm9.479 101.136c0 5.229-4.25 9.479-9.479 9.479-182.99 0-331.854-148.865-331.854-331.854 0-5.229 4.25-9.479 9.479-9.479h70.521c5.229 0 9.479 4.25 9.479 9.479 0 25.802 4.052 51.125 11.979 75.115 1.104 3.542.208 7.208-3.375 10.938L82.75 165.427a10.674 10.674 0 00-1 11.26c29.927 58.823 66.292 95.188 125.531 125.542 3.604 1.885 8.021 1.49 11.292-.979l49.677-37.635a9.414 9.414 0 019.667-2.25c24.156 7.979 49.479 12.021 75.271 12.021 5.229 0 9.479 4.25 9.479 9.479v70.323z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                                <div class="transx_icon_box_content_cont">
                                  <h5
                                    class="transx_icon_box_title"
                                    style="color: #000"
                                  >
                                    {{ $t('phone') }}
                                  </h5>
                                  <div class="transx_info_container">
                                    <p>
                                      <a
                                        href="tel:+842435770894"
                                        class="orange-color"
                                        style="color: #e8734a"
                                        >+842435770894</a
                                      >
                                      <!-- <br><a href="tel:+842435770894" class="orange-color">02435770894</a> -->
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3a7cafcb elementor-widget elementor-widget-transx_icon_box"
                          data-id="3a7cafcb"
                          data-element_type="widget"
                          data-widget_type="transx_icon_box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_icon_box_widget">
                              <div
                                class="transx_icon_box_item transx_view_type_3"
                              >
                                <div
                                  class="transx_icon_container"
                                  style="border: 1px solid #e8734a"
                                >
                                  <svg class="icon">
                                    <svg
                                      viewBox="0 0 479.058 479.058"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 015.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                                <div class="transx_icon_box_content_cont">
                                  <h5
                                    class="transx_icon_box_title"
                                    style="color: #000"
                                  >
                                    Email
                                  </h5>
                                  <div class="transx_info_container">
                                    <p>
                                      <a
                                        href="mailto:transx_info@gmail.com"
                                        class="orange-color"
                                        style="color: #e8734a"
                                        >vimclines@vimc-lines.com</a
                                      >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6e96e15b elementor-widget elementor-widget-transx_icon_box"
                          data-id="6e96e15b"
                          data-element_type="widget"
                          data-widget_type="transx_icon_box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_icon_box_widget">
                              <div
                                class="transx_icon_box_item transx_view_type_3"
                              >
                                <div
                                  class="transx_icon_container"
                                  style="border: 1px solid #e8734a"
                                >
                                  <svg class="icon">
                                    <svg
                                      viewBox="0 0 512 512"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 469.729c-55.847-66.338-152.035-197.217-152.035-284.301 0-83.834 68.202-152.036 152.035-152.036s152.035 68.202 152.035 152.035C408.034 272.515 311.861 403.37 256 469.729z"
                                      ></path>
                                      <path
                                        d="M256 92.134c-51.442 0-93.292 41.851-93.292 93.293S204.559 278.72 256 278.72s93.291-41.851 93.291-93.293S307.441 92.134 256 92.134zm0 153.194c-33.03 0-59.9-26.871-59.9-59.901s26.871-59.901 59.9-59.901 59.9 26.871 59.9 59.901-26.871 59.901-59.9 59.901z"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                                <div class="transx_icon_box_content_cont">
                                  <h5
                                    class="transx_icon_box_title"
                                    style="color: #000"
                                  >
                                    {{ $t('address') }}
                                  </h5>
                                  <div class="transx_info_container">
                                    <p style="color: #e8734a">
                                      {{ $t('location') }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-370835ee elementor-widget elementor-widget-transx_icon_box"
                          data-id="370835ee"
                          data-element_type="widget"
                          data-widget_type="transx_icon_box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_icon_box_widget">
                              <div
                                class="transx_icon_box_item transx_view_type_3"
                              >
                                <div
                                  class="transx_icon_container"
                                  style="border: 1px solid #e8734a"
                                >
                                  <svg class="icon">
                                    <svg
                                      viewBox="-21 0 512 512"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M389.332 160c-44.094 0-80-35.883-80-80s35.906-80 80-80c44.098 0 80 35.883 80 80s-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zm0 480c-44.094 0-80-35.883-80-80s35.906-80 80-80c44.098 0 80 35.883 80 80s-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zM80 336c-44.098 0-80-35.883-80-80s35.902-80 80-80 80 35.883 80 80-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zm0 0"
                                      ></path>
                                      <path
                                        d="M135.703 240.426c-5.57 0-10.988-2.903-13.91-8.063-4.375-7.68-1.707-17.453 5.973-21.824L325.719 97.684c7.656-4.414 17.449-1.727 21.8 5.976 4.376 7.68 1.708 17.45-5.972 21.824L143.594 238.336a16.03 16.03 0 01-7.89 2.09zm197.93 176a15.86 15.86 0 01-7.895-2.11L127.785 301.461c-7.68-4.371-10.344-14.145-5.972-21.824 4.351-7.7 14.125-10.367 21.804-5.973l197.95 112.852c7.68 4.375 10.347 14.144 5.976 21.824-2.945 5.183-8.363 8.086-13.91 8.086zm0 0"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                                <div class="transx_icon_box_content_cont">
                                  <h5
                                    class="transx_icon_box_title"
                                    style="color: #000"
                                  >
                                    {{ $t('followUs') }}
                                  </h5>
                                  <div class="transx_info_container">
                                    <ul class="transx_icon_box_socials">
                                      <li>
                                        <a
                                          class="btn"
                                          href="mailto:vimclines@vimc-lines.com"
                                          ><img
                                            src="../../../libhome/img/gmail1.svg"
                                            alt=""
                                            width="20px"
                                        /></a>
                                        <a
                                          class="btn"
                                          href="https://wa.me/84335299516"
                                          target="_blank"
                                          ><img
                                            src="../../../libhome/img/whatsapp1.svg"
                                            alt=""
                                            width="20px"
                                        /></a>
                                        <a
                                          class="btn"
                                          href="skype:suoinho_mk?chat"
                                          ><img
                                            src="../../../libhome/img/skype1.svg"
                                            alt=""
                                            width="20px"
                                        /></a>
                                        <a
                                          class="btn"
                                          href="https://zalo.me/0974040006"
                                          ><img
                                            src="../../../libhome/img/zalo1.svg"
                                            alt=""
                                            width="20px"
                                        /></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="col-7 elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5075a951 elementor-right"
                      data-id="5075a951"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-72dc12f8 elementor-widget elementor-widget-transx_heading"
                          data-id="72dc12f8"
                          data-element_type="widget"
                          data-widget_type="transx_heading.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="transx_heading_widget">
                              <h4
                                class="transx_heading"
                                id="message-form"
                              >
                                {{ $t('sendMess') }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-7d88533b elementor-widget elementor-widget-shortcode"
                          data-id="7d88533b"
                          data-element_type="widget"
                          data-widget_type="shortcode.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-shortcode">
                              <div
                                role="form"
                                class="wpcf7"
                                id="wpcf7-f4-p42-o1"
                                lang="en-US"
                                dir="ltr"
                              >
                                <div class="screen-reader-response">
                                  <p
                                    role="status"
                                    aria-live="polite"
                                    aria-atomic="true"
                                  ></p>
                                  <ul></ul>
                                </div>
                                <form
                                  action="/themes/transx-new/contact-us/#wpcf7-f4-p42-o1"
                                  method="post"
                                  class="wpcf7-form init"
                                  novalidate="novalidate"
                                  data-status="init"
                                  ref="form"
                                  @submit.prevent="sendEmail"
                                >
                                  <div class="transx_contact_form">
                                    <div class="transx_contact_form_field">
                                      <span
                                        class="wpcf7-form-control-wrap your-name"
                                      >
                                        <input
                                          v-model="name"
                                          type="text"
                                          name="your-name"
                                          size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true"
                                          aria-invalid="false"
                                          :placeholder="
                                            $i18n.locale === 'en'
                                              ? 'Full Name'
                                              : 'Họ và tên'
                                          "
                                        />
                                      </span>
                                    </div>
                                    <div class="transx_contact_form_field">
                                      <span
                                        class="wpcf7-form-control-wrap your-email"
                                      >
                                        <input
                                          v-model="email"
                                          type="email"
                                          name="your-email"
                                          size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                          aria-required="true"
                                          aria-invalid="false"
                                          placeholder="Email"
                                        />
                                      </span>
                                    </div>
                                    <div class="transx_contact_form_aria">
                                      <span
                                        class="wpcf7-form-control-wrap your-message"
                                      >
                                        <textarea
                                          v-model="message"
                                          name="your-message"
                                          cols="40"
                                          rows="10"
                                          class="wpcf7-form-control-aria wpcf7-textarea"
                                          aria-invalid="false"
                                          :placeholder="
                                            $i18n.locale === 'en'
                                              ? 'Message'
                                              : 'Tin nhắn'
                                          "
                                        ></textarea>
                                      </span>
                                    </div>
                                    <div class="transx_contact_form_button">
                                      <input
                                        type="submit"
                                        :value="
                                          $i18n.locale === 'en'
                                            ? 'Send Message'
                                            : 'Gửi tin nhắn'
                                        "
                                        :disabled="
                                          isLoading || isButtonDisabled
                                        "
                                        style="border: 2px solid #e8734a"
                                        class="has-spinner wpcf7-submit"
                                      /><span class="wpcf7-spinner"></span>
                                    </div>
                                  </div>
                                  <div
                                    class="wpcf7-response-output"
                                    aria-hidden="true"
                                  ></div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </section>
                <section
                  class="elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3903e669"
                  data-element_type="section"
                ></section>
              </div>
            </div>
          </div>
          <div class="transx_content_paging_wrapper"></div>
        </div>
      </div>
    </div>
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-18ef6fc elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
      data-id="18ef6fc"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
      style="height: 400px"
    >
      <div
        class="elementor-container elementor-column-gap-no"
        style="
          z-index: 3;
          position: absolute;
          height: 100%;
          width: 100%;
          padding: 0px;
          border-width: 0px;
          margin: 0px;
          left: 0px;
          top: 0px;
          touch-action: pan-x pan-y;
        "
      >
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-213b7a92"
          data-id="213b7a92"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-02a5f75 elementor-widget elementor-widget-google_maps"
              data-id="02a5f75"
              data-element_type="widget"
              data-widget_type="google_maps.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-custom-embed">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.6347957964826!2d105.8378493751975!3d21.00727158851638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac7802b8752b%3A0xca91cd914f429947!2zVOG7lW5nIGPDtG5nIHR5IEjDoG5nIEjhuqNpIFZp4buHdCBOYW0gLSBWSU1D!5e0!3m2!1svi!2s!4v1711503233131!5m2!1svi!2s"
                    width="100%"
                    height="300px"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import Swal from 'sweetalert2';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
  // mounted() {
  //   this.scrollToMessageForm();
  // },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      isLoading: false,
      isButtonDisabled: false,
      isToastVisible: false,
    };
  },
  methods: {
    sendEmail() {
      if (this.isToastVisible) return;
      this.isLoading = true; // Bắt đầu gửi email
      this.isButtonDisabled = true;

      if (!this.name || !this.email || !this.message) {
        this.showToast(
          this.$i18n.locale === 'en'
            ? 'Please complete all required fields'
            : 'Vui lòng nhập đủ thông tin ',
          'error'
        );
        this.isLoading = false;
        this.isButtonDisabled = false;
        return;
      }
      const data = {
        from: this.email,
        name: this.name,
        text: this.message,
      };

      fetch('https://sendmail-tl91.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.text())
        .then((data) => {
          this.$toast.success(
            `${
              this.$i18n.locale === 'en'
                ? 'Email sent successfully'
                : 'Email đã được gửi'
            }`
          );
          // Clear form fields after successful submission
          this.name = '';
          this.email = '';
          this.message = '';
        })
        .catch((error) => {
          this.$toast.error(
            `${
              this.$i18n.locale === 'en'
                ? 'Error when sending email'
                : 'Có lỗi khi gửi email'
            }`
          );
        })
        .finally(() => {
          this.isLoading = false; // Kết thúc gửi email
          this.isButtonDisabled = false;
        });
    },
    showToast(message, type) {
      if (this.isToastVisible) return;
      this.isToastVisible = true;

      if (type === 'error') {
        this.$toast.error(message);
      } else {
        this.$toast.success(message);
      }

      setTimeout(() => {
        this.isToastVisible = false;
      }, 3000); // 3 giây cho thông báo hiển thị
    },
    // scrollToMessageForm() {
    //   setTimeout(() => {
    //     const element = document.querySelector('#message-form');
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }, 500); // Adjust the timeout value if necessary
    // },
    clearForm() {
      // Reset form fields to empty strings
      this.email = '';
      this.name = '';
      this.message = '';
    },
  },
};
('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});
</script>
<style>
/*
Removes white gap between slides
*/

.location-contact {
  padding: 15px 0 0 0 !important;
}
.col-p {
  padding: 0 !important;
}

.container-contact {
  max-width: 1270px !important;
}

.container-call {
  max-width: 1440px !important;
}

.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

.carousel-caption {
  bottom: 45px !important;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}

.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}

.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: 0.85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  font-family: Inter, 'sans-serif';
  font-size: 200px;
  letter-spacing: -0.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}

.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}

.elementor-background-overlay {
  background-image: url('../../../libhome/img/contacts-map.png');
  background-position: 50% 24px;
  background-repeat: no-repeat;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_container {
  width: 60px;
  height: 60px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 19px;
  border: 1px solid #e8734a;
  color: #e8734a;
}

.transx_icon_box_item.transx_view_type_3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.transx_heading_widget h4 {
  font-size: 25px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.transx_contact_form_field {
  width: 50%;
  display: inline-block;
  padding-left: 11px;
}

.transx_contact_form_field input {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
}

.transx_contact_form_aria textarea {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
}

.transx_contact_form_field input[type='email'] {
  height: 50px;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif !important;
  margin-bottom: 20px !important;
}

.transx_contact_form_aria {
  padding-left: 11px;
}

.wpcf7-form-control-aria {
  padding-left: 20px;
  width: 100%;
  display: inline-block;
  height: 195px;
  border: 1px solid;
  font-size: 14px;
  line-height: 30px;
  color: #000;
  background: #fff;
  border-color: #e2e2e2;
}

.transx_contact_form_button {
  width: 30%;
  display: inline-block;
  padding-left: 11px;
  text-transform: uppercase;
}

.transx_contact_form_button input {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}

.wpcf7-submit {
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 2px solid #3973d4;
  border-radius: 0 0 15px 0;
  transition: all 0.3s;
  color: #414a53;
  text-transform: uppercase;
}

.wpcf7-submit:hover {
  color: #fff;
  background-color: #e8734a;
  border: 2px solid #3973d4;
}

.wpcf7-form-control {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border: 1px solid;
  padding: 0 20px;
  margin-bottom: 20px;
  box-shadow: none;
  border-radius: 0;
  transition: all 0.3s;
  outline: 0;
  color: #000;
  background: #fff;
  border-color: #e2e2e2;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_box_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #767f7f;
}

.transx_info_container {
  font-size: 12px;
  font-weight: 400;
  color: #767f7f;
  display: flex;
  justify-content: start;
}

.transx_icon_box_content_cont {
  align-items: center;
  margin-top: 15px;
}

.transx_icon_box_item .transx_info_container .transx_icon_box_socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.transx_icon_box_item.transx_view_type_3
  .transx_info_container
  .transx_icon_box_socials
  li:not(:last-of-type) {
  margin: 0 18px 0 0;
}
.container {
  max-width: 1440px;
}
</style>
