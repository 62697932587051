var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "blog" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc diamond.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("blogs")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "transx_page_content_wrapper" }, [
        _c("div", { staticClass: "blog" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "post-36 page type-page status-publish has-post-thumbnail hentry",
                  attrs: { id: "post-36" },
                },
                [
                  _c("div", { staticClass: "transx_page_content_container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "transx_page_content_wrapper transx_page_title_show",
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row transx_sidebar_right transx_bg_color_alt",
                            },
                            [
                              _c("div", { staticClass: "col-lg-8 col-xl-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "transx_content_wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "elementor elementor-36",
                                        attrs: {
                                          "data-elementor-type": "wp-page",
                                          "data-elementor-id": "36",
                                          "data-elementor-settings": "[]",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-section-wrap",
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "elementor-section-blog elementor-top-section elementor-element elementor-element-3561e36f elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                                attrs: {
                                                  "data-id": "3561e36f",
                                                  "data-element_type":
                                                    "section",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-container elementor-column-gap-default",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54b91072",
                                                        attrs: {
                                                          "data-id": "54b91072",
                                                          "data-element_type":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-widget-wrap elementor-element-populated",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "elementor-element elementor-element-43bce01d elementor-widget elementor-widget-transx_blog_listing",
                                                                attrs: {
                                                                  "data-id":
                                                                    "43bce01d",
                                                                  "data-element_type":
                                                                    "widget",
                                                                  "data-widget_type":
                                                                    "transx_blog_listing.default",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "elementor-widget-container",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "transx_blog_listing_widget",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "transx_blog_listing_wrapper transx_view_type_1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "transx_blog_listing_image_container",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            position:
                                                                                              "absolute",
                                                                                            bottom:
                                                                                              "0",
                                                                                            padding:
                                                                                              "8px 15px",
                                                                                            "background-color":
                                                                                              "#e8734e",
                                                                                            "font-size":
                                                                                              "14px",
                                                                                            "font-weight":
                                                                                              "700",
                                                                                            color:
                                                                                              "aliceblue",
                                                                                            "border-radius":
                                                                                              "2px",
                                                                                            "margin-left":
                                                                                              "16px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "establishment"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_img--bg",
                                                                                        attrs:
                                                                                          {
                                                                                            src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-2.jpg",
                                                                                            alt: "Featured Image",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm._m(
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "transx_blog_listing_content_wrapper",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_meta",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_date",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "day1"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_separator",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "/"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_author",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? "by"
                                                                                                  : "bởi"
                                                                                              ) +
                                                                                                " Vimc Lines"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "h3",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_title",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                href: "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "blogtTitle1"
                                                                                                  )
                                                                                                )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_excerpt",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `On April 1, 2024, VIMC Container Transport Joint Stock Company (VIMC LINES) successfully held its inaugural shareholders' meeting to establish the Joint Stock Company. This marks a new milestone in the development strategy of the Vietnam Maritime Corporation (VIMC), aiming to enhance its competitive capacity and assert its position in the domestic and international container transport market.`
                                                                                                : "Ngày 01/4/2024, Công ty cổ phần Vận tải container VIMC (VIMC LINES) đã tổ chức thành công Đại hội đồng cổ đông thành lập Công ty cổ phần. Đánh dấu bước ngoặt mới trong chiến lược phát triển của Tổng công ty Hàng hải Việt Nam (VIMC), hướng đến mục tiêu nâng cao năng lực cạnh tranh và khẳng định vị thế trên thị trường vận tải container trong nước và quốc tế."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `The executive board chaired the shareholders' meeting.`
                                                                                                  : "Đoàn chủ toạ điều hành Đại hội"
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` The shareholders' meeting was attended by Comrade Le Anh Son, Secretary of the Party Committee and Chairman of the Board of Directors of VIMC; Comrade Nguyen Canh Tinh, General Director of VIMC; representatives of leadership from VIMC's specialized departments, leaders of VIMC Shipping, and shareholders representing 101,500,000 shares of VIMC LINES.`
                                                                                                : "Tham dự Đại hội có đồng chí Lê Anh Sơn, Bí thư Đảng ủy, Chủ tịch HĐQT VIMC; đồng chí Nguyễn Cảnh Tĩnh, Tổng giám đốc VIMC; đại diện lãnh đạo các ban chuyên môn nghiệp vụ của VIMC, lãnh đạo Công ty Vận tải biển VIMC Shipping, cùng các cổ đông đại diện cho 101.500.000 cổ phần của VIMC LINES."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/DSC3898.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `VIMC's Deputy General Director, Nguyen Ngoc Anh, presiding over the shareholders' meeting, invited shareholders`
                                                                                                  : "Phó Tổng giám đốc VIMC – Nguyễn Ngọc Ánh, Chủ tọa Đại hội đề nghị các cổ đông cổ đông thảo luận, đóng góp ý"
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "-5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `to participate in the meeting.`
                                                                                                  : "kiến đối các nội dung trình tại Đại hội."
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                            "line-height":
                                                                                              "25px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` At the shareholders' meeting, shareholders discussed and voted on the Articles of Association of the Company; Internal regulations on corporate governance; Rules of operation of the Board of Directors; Rules of operation of the Supervisory Board; Business production and investment plan for 2024; Selection of the auditor for the financial statements of 2024; and the Salary and remuneration fund plan for 2024 for the Board of Directors and the Supervisory Board.`
                                                                                                : "Tại Đại hội các cổ đông thảo luận và biểu quyết thông qua Điều lệ Công ty; Quy chế nội bộ về quản trị công ty; Quy chế Hoạt động của Hội đồng quản trị; Quy chế hoạt động của Ban Kiểm soát; Kế hoạch hoạt động sản xuất kinh doanh và đầu tư năm 2024; Lựa chọn đơn vị kiểm toán Báo cáo tài chính năm 2024; Quỹ tiền lương, thù lao kế hoạch năm 2024 của Hội đồng quản trị, Ban Kiểm soát."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-2.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `Chairman of VIMC's Board of Directors, Le Anh Son, presented flowers and extended congratulations to the five`
                                                                                                  : "Chủ tịch HĐQT VIMC – Lê Anh Sơn tặng hoa và chúc mừng 5 đồng chí được bầu vào Hội đồng quản trị VIMC LINES"
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "-5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  this
                                                                                                    .$i18n
                                                                                                    .locale ===
                                                                                                    "en"
                                                                                                    ? `comrades elected to the Board of Directors of VIMC Lines for the term 2024-2029.`
                                                                                                    : "nhiệm kỳ 2024 -2029"
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-5.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `The newly appointed Chairman of the Board of Directors of VIMC LINES, Nguyen Ngoc Anh, presented flowers and`
                                                                                                  : "Tân chủ tịch HĐQT VIMC LINES Nguyễn Ngọc Ánh tặng hoa, chúc mừng bà Đỗ Thị Ngọc Trang được bổ nhiệm làm"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "-5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `congratulated Ms. Do Thi Ngoc Trang on her appointment as the General Director.`
                                                                                                  : "Tổng giám đốc"
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-4.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `The General Director of VIMC, Nguyen Canh Tinh, presented flowers and congratulated three comrades elected to the`
                                                                                                  : "Tổng giám đốc VIMC – Nguyễn Cảnh Tĩnh tặng hoa và chúc mừng 3 đồng chí được bầu vào Ban Kiểm soát VIMC"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "-5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `Supervisory Board of VIMC Lines for the term 2024-2029.`
                                                                                                  : "Lines nhiệm kỳ 2024 -2029"
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `The shareholders' meeting also elected 5 members to the Board of Directors and 3 members to the Supervisory Board for the term 2024-2029. Mr. Nguyen Ngoc Anh, Deputy General Director of VIMC, was elected as Chairman of the Board of Directors; Ms. Do Thi Ngoc Trang was appointed by the Board of Directors as the General Director; and Ms. Phan Thi Nhi Ha was elected as the Head of the Supervisory Board.`
                                                                                                : "Đại hội cũng đã bầu ra 5 thành viên Hội đồng quản trị và 3 thành viên Ban Kiểm soát nhiệm kỳ 2024 – 2029. Ông Nguyễn Ngọc Ánh, Phó Tổng giám đốc VIMC được bầu làm Chủ tịch HĐQT; Bà Đỗ Thị Ngọc Trang được HĐQT bổ nhiệm làm Tổng giám đốc; Bà Phan Thị Nhị Hà được bầu làm Trưởng ban Kiểm soát."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "650px",
                                                                                                "object-fit":
                                                                                                  "contain",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-3.jpg",
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `Shareholders voted to approve the resolution of the shareholders' meeting to establish VIMC LINES.`
                                                                                                  : "Cổ đông bỏ phiếu thông qua Nghị quyết Đại hội đồng cổ đông thành lập VIMC LINES"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `After a half-day of work, the minutes and resolutions of the shareholders' meeting to establish VIMC LINES were unanimously approved by all attending shareholders, representing 100% of the total votes. The successful organization of the shareholders' meeting officially marks the establishment of VIMC LINES.`
                                                                                                : "Sau 1/2 ngày làm việc, Biên bản và Nghị quyết Đại hội đồng cổ đông thành lập Công ty cổ phần Vận tải container VIMC đã được các cổ đông nhất trí thông qua với 100% tổng số phiếu biểu quyết của các cổ đông tham dự. Tổ chức thành công Đại hội đồng cổ đông thành lập Công ty cổ phần đã chính thức đánh dấu sự ra đời VIMC LINES."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `With a charter capital of 1,015 billion VND, VIMC Lines aims to become a leading container transportation company in Vietnam, providing high-quality services at competitive prices to meet the increasing market demand. VIMC LINES will focus on enhancing service quality in the domestic container shipping market for key commodities such as agricultural products, consumer goods, construction materials, and more. It plans to develop operational activities and collaborate with domestic and international shipping lines and AGENT such as Dong Do Marine Transport Joint Stock Company, Vosco, Hyundai Merchant Marine Co., Ltd., Gold Star Line Ltd., and Zim.`
                                                                                                : "Với vốn điều lệ 1.015 tỷ đồng, VIMC LINES đặt mục tiêu trở thành doanh nghiệp vận tải container hàng đầu Việt Nam, cung cấp dịch vụ chất lượng cao, giá cả cạnh tranh, đáp ứng nhu cầu ngày càng tăng của thị trường. VIMC LINES sẽ tập trung nâng cao chất lượng dịch vụ của thị phần vận chuyển container bằng tàu biển tuyến nội địa đối với một số mặt hàng trọng yếu như: hàng nông sản, hàng tiêu dùng, vật liệu xây dựng, và nhiều hơn nữa. Phát triển hoạt động khai thác, hợp tác với một số hãng tàu trong và ngoài nước trong hoạt động khai thác và đại lý tàu như: Công ty TNHH MTV vận tải Biển Đông, Vosco, Hyundai Merchant Marrine Co., Ltd; Gold Star Line Ltd; Zim, …"
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `Additionally, it will expand its services as a container shipping agent, operating inland and intra-Asia routes including Cai Mep, Hong Kong, Singapore, Malaysia, and India. In the long term, once it establishes a stable and reputable operational framework, VIMC LINES aims to attract opportunities to act as an agent for other domestic and international shipping lines.`
                                                                                                : "Đồng thời, phát triển dịch vụ Đại lý khai thác vận tải tàu container: Khai thác tuyến nội địa và các tuyến Nội Á: Cái Mép, Hong Kong, Singapore, Malaysia, Ấn Độ. Về dài hạn khi tạo dựng được bộ máy hoạt động ổn định và có thương hiệu, VIMC LINES sẽ thu hút làm dịch vụ đại lý cho các hãng tàu khác trong và ngoài nước."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                            padding:
                                                                                              "10px 0 0 0",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `The establishment of VIMC Lines represents a strategic initiative to realize the Development Strategy for the 2021-2030 period, the Business Production and Investment Plan for 2021-2025, and the Restructuring Plan for 2021-2025 of VIMC. VIMC Lines aims to develop an integrated supply chain encompassing sea transportation, port services, and logistics, contributing to the successful implementation of Vietnam's sustainable development strategy for the maritime economy by 2030 and its vision towards 2045. The inception of VIMC LINES promises to bring new vitality to Vietnam's container transportation industry, enhancing competitive capabilities and affirming Vietnam's position in the international market.`
                                                                                                : ` Việc thành lập VIMC LINES là một chủ trương đúng đắn, nhằm hiện thực hóa Chiến lược phát triển giai đoạn 2021-2030, Kế hoạch sản xuất kinh doanh và đầu tư giai đoạn 2021-2025, Đề án Cơ cấu lại giai đoạn 2021-2025 của VIMC. VIMC LINES hướng đến mục tiêu phát triển chuỗi cung ứng dịch vụ tích hợp vận tải biển – cảng biển – dịch vụ logistics, góp phần thực hiện thành công Chiến lược phát triển bền vững kinh tế biển Việt Nam đến năm 2030, tầm nhìn đến năm 2045. Sự ra đời của VIMC LINES hứa hẹn sẽ mang đến những luồng gió mới cho ngành vận tải container Việt Nam, góp phần nâng cao năng lực cạnh tranh và khẳng định vị thế của Việt Nam trên thị trường quốc tế.`
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "hr"
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            "align-items":
                                                                                              "center",
                                                                                            "justify-content":
                                                                                              "space-between",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "margin-right":
                                                                                                      "5px",
                                                                                                    "font-weight":
                                                                                                      "600",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "#" +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "establishment"
                                                                                                      )
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._m(
                                                                                          2
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._m(
                                                                          3
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "transx_content_paging_wrapper",
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "transx_sidebar custom-padding col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_search",
                                      attrs: { id: "search-2" },
                                    },
                                    [
                                      _c(
                                        "form",
                                        {
                                          staticClass: "transx_search_form",
                                          attrs: {
                                            name: "search_form",
                                            id: "search-395",
                                          },
                                        },
                                        [
                                          _vm._m(4),
                                          _c("input", {
                                            staticClass: "form__field",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "text",
                                              name: "s",
                                              value: "",
                                              placeholder:
                                                this.$i18n.locale === "en"
                                                  ? "Search by title"
                                                  : "Tìm kiếm theo tiêu đề",
                                              title: "Search the site...",
                                            },
                                          }),
                                          _c("div", { staticClass: "clear" }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("category")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("companionCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("establishmentCom")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("seafarer"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("containerTerminal")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("annoucement"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("archive")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day1")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day3")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day2")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day4")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day0")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day5")))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-10",
                                      attrs: { id: "custom_html-2" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "textwidget custom-html-widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_contact-trigger transx_contact-trigger--style-2",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "transx_contact-trigger__img",
                                                attrs: {
                                                  src: "https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2024/01/contact_background.png",
                                                  alt: "img",
                                                },
                                              }),
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "transx_contact-trigger__title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("helper")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("p", {
                                                staticClass:
                                                  "transx_contact-trigger__text",
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "transx_button_contactus",
                                                  attrs: { href: "/contact" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("contactUs")
                                                        ) + "  "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "/",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "icon arrow-icon",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    id: "next-arrow",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                      fill: "#fff",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_tag_cloud",
                                      attrs: { id: "tag_cloud-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        { staticClass: "widget_title" },
                                        [_vm._v(_vm._s(_vm.$t("tag")))]
                                      ),
                                      _c("hr"),
                                      _c("div", { staticClass: "tagcloud" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/0",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("companion")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/1",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("establishment"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-32 tag-link-position-2",
                                            attrs: {
                                              href: "/blogs/detail/2",
                                              "aria-label": "Cargo (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("seafarer")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/3",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("terminal")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/4",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("annoucement"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/5",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("signCom")))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("FooterHome"),
      _vm._m(5),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_post_cat_cont" }, [
      _c("div", { staticClass: "transx_post_cat_wrapper" }, [
        _c("a", { staticClass: "link link_text", attrs: { href: "" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticStyle: { width: "650px", "object-fit": "contain" },
        attrs: {
          src: "https://vimc.co/wp-content/uploads/2023/01/VIMC_tau-1.23.jpg",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social-buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "skype:suoinho_mk?chat" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "https://zalo.me/0974040006" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_pagination" }, [
      _c("nav", {
        staticClass: "navigation pagination",
        attrs: { role: "navigation", "aria-label": "Posts" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "transx_icon_search", staticStyle: { display: "none" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/icons/icons8-search.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }