var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                            attrs: { id: "carousel-item-caption" },
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Booking")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "booking-page", attrs: { id: "booking-page" } },
        [
          _c(
            "div",
            { staticClass: "container container-booking" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: {
                        "px-1": _vm.$vuetify.breakpoint.mobile,
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "mt-12": _vm.$vuetify.breakpoint.mobile,
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class: {
                                        "px-1": _vm.$vuetify.breakpoint.mobile,
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-card", {
                                            staticStyle: { overflow: "hidden" },
                                            attrs: {
                                              rounded: "lg",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    class: {
                                      "px-1": _vm.$vuetify.breakpoint.mobile,
                                    },
                                    attrs: { cols: "7" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "0" },
                                [_c("ContainerTrackingSearch")],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "1" },
                                [
                                  _c("VesselScheduleSearch", {
                                    attrs: { "request-open-in-new-tab": "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", {
                    class: {
                      "px-1": _vm.$vuetify.breakpoint.mobile,
                    },
                    attrs: { cols: "7" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-app",
        [
          _c(
            "v-main",
            { staticClass: "grey lighten-4" },
            [
              _c(
                "v-container",
                { staticClass: "pa-6", attrs: { fluid: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-6 search-bar" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-1",
                                  attrs: { cols: "12", sm: "3" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "search-field",
                                    attrs: {
                                      label: "Port Kelang",
                                      "prepend-inner-icon": "mdi-anchor",
                                      solo: "",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-1",
                                  attrs: { cols: "12", sm: "3" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "search-field",
                                    attrs: {
                                      label: "Calcutta",
                                      "prepend-inner-icon": "mdi-map-marker",
                                      solo: "",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-1",
                                  attrs: { cols: "12", sm: "3" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "search-field",
                                    attrs: {
                                      label: "02 Dec, 2024",
                                      "prepend-inner-icon": "mdi-calendar",
                                      solo: "",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-1",
                                  attrs: { cols: "12", sm: "1" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "search-button",
                                      attrs: {
                                        color: "primary",
                                        icon: "",
                                        large: "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-magnify")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "filter-card" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "filter-title" },
                                [
                                  _vm._v(" Filter "),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "clear-button",
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-subheader",
                                    { staticClass: "filter-subheader" },
                                    [_vm._v("Included services")]
                                  ),
                                  _c("v-checkbox", {
                                    staticClass: "filter-checkbox",
                                    attrs: {
                                      label: "Ocean freight",
                                      "hide-details": "",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "filter-checkbox",
                                    attrs: {
                                      label: "Place of loading",
                                      "hide-details": "",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "filter-checkbox",
                                    attrs: {
                                      label: "Port of origin",
                                      "hide-details": "",
                                    },
                                  }),
                                  _c(
                                    "v-subheader",
                                    { staticClass: "filter-subheader" },
                                    [_vm._v("Price")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "blue" } },
                                        [_vm._v("mdi-circle")]
                                      ),
                                      _c("v-divider", {
                                        staticStyle: {
                                          "flex-grow": "1",
                                          height: "3px",
                                          width: "100% !important",
                                          "background-color": "#1976d2",
                                        },
                                      }),
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "blue" } },
                                        [_vm._v("mdi-circle")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between slider-labels",
                                    },
                                    [
                                      _c("span", [_vm._v("2288")]),
                                      _c("span", [_vm._v("3396")]),
                                    ]
                                  ),
                                  _c(
                                    "v-subheader",
                                    { staticClass: "filter-subheader" },
                                    [_vm._v("Transit time")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "blue" } },
                                        [_vm._v("mdi-circle")]
                                      ),
                                      _c("v-divider", {
                                        staticStyle: {
                                          "flex-grow": "1",
                                          height: "3px",
                                          width: "100% !important",
                                          "background-color": "#1976d2",
                                        },
                                      }),
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", color: "blue" } },
                                        [_vm._v("mdi-circle")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between slider-labels",
                                    },
                                    [
                                      _c("span", [_vm._v("29 days")]),
                                      _c("span", [_vm._v("35 days")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "9" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-6 sort-card" },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "disclaimer" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v("mdi-information")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "subtitle-2" },
                                        [_vm._v("Disclaimer")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      staticStyle: { height: "48px" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "sort-button mr-2",
                                          staticStyle: {
                                            "text-transform": "capitalize",
                                          },
                                          attrs: {
                                            text: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v(" Sort by ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "recommend-button",
                                          staticStyle: {
                                            "text-transform": "capitalize",
                                          },
                                          attrs: {
                                            text: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v(" Recommended ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-6 custom-alert",
                              attrs: {
                                type: "warning",
                                "colored-border": "",
                                border: "left",
                              },
                            },
                            [
                              _vm._v(
                                " There are no valid rates for this route now. For indication, please see past rates for the last 3 months and please request a quote. "
                              ),
                            ]
                          ),
                          _vm.vesselDetails
                            ? _c(
                                "v-card",
                                { key: _vm.i, staticClass: "mb-6 route-card" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        size: "40",
                                                        color: "grey lighten-2",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { medium: "" },
                                                        },
                                                        [_vm._v("mdi-ferry")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "subtitle-1 font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.vesselDetails
                                                                .vessel.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption red--text",
                                                      },
                                                      [_vm._v("Expired")]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-package-variant")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [_vm._v("20'ST")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-clock-outline")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calculateDaysBetween(
                                                          _vm.vesselDetails.eta,
                                                          _vm.vesselDetails.etd
                                                        )
                                                      ) +
                                                      " days"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-3" }),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "caption mb-1" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-anchor")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.vesselDetails
                                                          .pol_terminal.name
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "caption" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-map-marker")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.vesselDetails
                                                          .pod_terminal.name
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "subtitle-1 font-weight-bold mb-1",
                                                },
                                                [_vm._v(" USD 2,288 ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "request-button",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.showQuotationDetail,
                                                  },
                                                },
                                                [_vm._v("Request")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-card",
                            { staticClass: "quick-request-card" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "8" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    color: "primary",
                                                    size: "36",
                                                  },
                                                },
                                                [_vm._v("mdi-credit-card")]
                                              ),
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "subtitle-1 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Need more options? Quick request "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "caption" },
                                                  [
                                                    _vm._v(
                                                      " Fill up the short form so that our team can contact you and provide an individual offer. "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12", sm: "4" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "request-button",
                                              attrs: { color: "primary" },
                                            },
                                            [_vm._v("Make a request")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FooterHome"),
      _vm._m(0),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }