var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "blog" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc diamond.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("blogs")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "transx_page_content_wrapper" }, [
        _c("div", { staticClass: "blog" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "post-36 page type-page status-publish has-post-thumbnail hentry",
                  attrs: { id: "post-36" },
                },
                [
                  _c("div", { staticClass: "transx_page_content_container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "transx_page_content_wrapper transx_page_title_show",
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row transx_sidebar_right transx_bg_color_alt",
                            },
                            [
                              _c("div", { staticClass: "col-lg-8 col-xl-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "transx_content_wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "elementor elementor-36",
                                        attrs: {
                                          "data-elementor-type": "wp-page",
                                          "data-elementor-id": "36",
                                          "data-elementor-settings": "[]",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-section-wrap",
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "elementor-section-blog elementor-top-section elementor-element elementor-element-3561e36f elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                                attrs: {
                                                  "data-id": "3561e36f",
                                                  "data-element_type":
                                                    "section",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-container elementor-column-gap-default",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54b91072",
                                                        attrs: {
                                                          "data-id": "54b91072",
                                                          "data-element_type":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-widget-wrap elementor-element-populated",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "elementor-element elementor-element-43bce01d elementor-widget elementor-widget-transx_blog_listing",
                                                                attrs: {
                                                                  "data-id":
                                                                    "43bce01d",
                                                                  "data-element_type":
                                                                    "widget",
                                                                  "data-widget_type":
                                                                    "transx_blog_listing.default",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "elementor-widget-container",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "transx_blog_listing_widget",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "transx_blog_listing_wrapper transx_view_type_1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "blog-content",
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .paginatedBlogs
                                                                                  .length >
                                                                                0
                                                                                  ? _vm._l(
                                                                                      _vm.paginatedBlogs,
                                                                                      function (
                                                                                        blog
                                                                                      ) {
                                                                                        return _c(
                                                                                          "BlogListingItem",
                                                                                          {
                                                                                            key: blog.id,
                                                                                            attrs:
                                                                                              {
                                                                                                id: blog.id,
                                                                                                imageSrc:
                                                                                                  blog.imageSrc,
                                                                                                category:
                                                                                                  blog.category,
                                                                                                date: blog.date,
                                                                                                title:
                                                                                                  blog.title,
                                                                                                content:
                                                                                                  blog.content,
                                                                                                detailLink:
                                                                                                  blog.detailLink,
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              width:
                                                                                                "1100px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? "No blogs found."
                                                                                                  : "Không tìm thấy bài viết nào."
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "transx_pagination",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "nav",
                                                                              {
                                                                                staticClass:
                                                                                  "navigation pagination",
                                                                                attrs:
                                                                                  {
                                                                                    role: "navigation",
                                                                                    "aria-label":
                                                                                      "Posts",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "ul",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pagination-list",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "page-item arrow-item",
                                                                                        class:
                                                                                          {
                                                                                            disabled:
                                                                                              _vm.currentPage ===
                                                                                              1,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.goToPage(
                                                                                                _vm.currentPage -
                                                                                                  1
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._m(
                                                                                          0
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.totalPages,
                                                                                      function (
                                                                                        page
                                                                                      ) {
                                                                                        return _c(
                                                                                          "li",
                                                                                          {
                                                                                            key: page,
                                                                                            staticClass:
                                                                                              "page-item",
                                                                                            class:
                                                                                              {
                                                                                                active:
                                                                                                  page ===
                                                                                                  _vm.currentPage,
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.goToPage(
                                                                                                    page
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "page-link",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    page
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "page-item arrow-item",
                                                                                        class:
                                                                                          {
                                                                                            disabled:
                                                                                              _vm.currentPage ===
                                                                                              _vm.totalPages,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.goToPage(
                                                                                                _vm.currentPage +
                                                                                                  1
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._m(
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  2
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "transx_content_paging_wrapper",
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "transx_sidebar custom-margin col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_search",
                                      attrs: { id: "search-2" },
                                    },
                                    [
                                      _c(
                                        "form",
                                        {
                                          staticClass: "transx_search_form",
                                          attrs: {
                                            name: "search_form",
                                            method: "get",
                                            action: "/home",
                                            id: "search-395",
                                          },
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleSearch.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "transx_icon_search",
                                              on: { click: _vm.handleSearch },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../../assets/icons/icons8-search.svg"),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchQuery,
                                                expression: "searchQuery",
                                              },
                                            ],
                                            staticClass: "form__field",
                                            attrs: {
                                              type: "text",
                                              name: "s",
                                              value: "",
                                              placeholder:
                                                this.$i18n.locale === "en"
                                                  ? "Search by title"
                                                  : "Tìm kiếm theo tiêu đề",
                                              title: "Search the site...",
                                            },
                                            domProps: {
                                              value: _vm.searchQuery,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.searchQuery =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c("div", { staticClass: "clear" }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("category")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("companionCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("establishmentCom")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("seafarer"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("containerTerminal")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("annoucement"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("archive")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day1")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day3")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day2")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day4")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day0")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day5")))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-10",
                                      attrs: { id: "custom_html-2" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "textwidget custom-html-widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_contact-trigger transx_contact-trigger--style-2",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "transx_contact-trigger__img",
                                                attrs: {
                                                  src: "https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2024/01/contact_background.png",
                                                  alt: "img",
                                                },
                                              }),
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "transx_contact-trigger__title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("helper")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("p", {
                                                staticClass:
                                                  "transx_contact-trigger__text",
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "transx_button_contactus",
                                                  attrs: { href: "/contact" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("contactUs")
                                                        ) + "  "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "/",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "icon arrow-icon",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    id: "next-arrow",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                      fill: "#fff",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_tag_cloud",
                                      attrs: { id: "tag_cloud-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        { staticClass: "widget_title" },
                                        [_vm._v(_vm._s(_vm.$t("tag")))]
                                      ),
                                      _c("hr"),
                                      _c("div", { staticClass: "tagcloud" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/0",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("companion")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/1",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("establishment"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-32 tag-link-position-2",
                                            attrs: {
                                              href: "/blogs/detail/2",
                                              "aria-label": "Cargo (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("seafarer")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/3",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("terminal")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/4",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("annoucement"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/5",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("signCom")))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("FooterHome"),
      _vm._m(2),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "page-link", attrs: { "aria-label": "Previous" } },
      [_c("i", { staticClass: "fas fa-chevron-left" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "page-link", attrs: { "aria-label": "Next" } },
      [_c("i", { staticClass: "fas fa-chevron-right" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }