<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      VIMC&nbsp;LINES
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Booking') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="booking-page"
      id="booking-page"
    >
      <div class="container container-booking">
        <v-row>
          <v-col
            cols="12"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
            <div>
              <div
                :class="{
                  'mt-12': $vuetify.breakpoint.mobile,
                }"
              >
                <v-row
                  no-gutters
                  class="my-2"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="5"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-card
                        rounded="lg"
                        width="100%"
                        style="overflow: hidden"
                      >
                        <!-- <v-tabs
                          v-model="tab"
                          :active-class="searchMenuTabs[tab].color"
                          height="40px"
                          grow
                        >
                          <v-tabs-slider
                            :color="`${searchMenuTabs[tab].color}`"
                          ></v-tabs-slider>
                          <v-tab
                            v-for="item in searchMenuTabs"
                            :key="item.key"
                            :active-class="`${item.color} ${item.colorModifier} ${item.color}--text`"
                            :ripple="false"
                          >
                            <v-icon
                              left
                              v-text="item.icon"
                              :color="item.color"
                            >
                            </v-icon>
                            {{ item.title }}
                          </v-tab>
                        </v-tabs> -->
                      </v-card>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="7"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                  </v-col>
                </v-row>
              </div>

              <v-tabs-items v-model="tab">
                <v-tab-item key="0">
                  <ContainerTrackingSearch />
                </v-tab-item>
                <v-tab-item key="1">
                  <VesselScheduleSearch request-open-in-new-tab />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
          <v-col
            cols="7"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
          </v-col>
        </v-row>
      </div>
    </div>
    <v-app>
      <v-main class="grey lighten-4">
        <v-container class="pa-6">
          <!-- Header Navigation -->
          <div class="d-flex justify-space-between align-center mb-6">
            <v-btn
              text
              color="primary"
              class="pl-0"
              @click="goBack"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              Back
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="newSearch"
            >
              New search
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <!-- Quotation Header -->
          <h1 class="text-h4 font-weight-bold mb-6">
            Quotation {{ quotationId }} | USD {{ price }}
          </h1>

          <v-row>
            <v-col cols="12">
              <!-- Route Summary Card -->
              <v-card
                class="mb-6"
                v-if="vesselDetails"
              >
                <v-card-text>
                  <v-row
                    align="center"
                    no-gutters
                  >
                    <v-col cols="6">
                      <div class="d-flex align-center">
                        <v-avatar
                          size="40"
                          color="grey lighten-2"
                          class="mr-3"
                        >
                          <v-icon medium>mdi-ferry</v-icon>
                        </v-avatar>
                        <div>
                          <div class="subtitle-1 font-weight-medium">
                            {{ vesselDetails.vessel.name }}
                          </div>
                          <div class="caption red--text">{{ status }}</div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-center"
                    >
                      <v-icon
                        small
                        class="mr-1"
                        >mdi-package-variant</v-icon
                      >
                      <span class="caption">{{ containerSize }}</span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-center"
                    >
                      <v-icon
                        small
                        class="mr-1"
                        >mdi-clock-outline</v-icon
                      >
                      <span class="caption">
                        {{
                          calculateDaysBetween(
                            vesselDetails.eta,
                            vesselDetails.etd
                          )
                        }}
                        days</span
                      >
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-center"
                    >
                      <v-icon>mdi-airplane</v-icon>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-6"
                    no-gutters
                  >
                    <v-col cols="4">
                      <div class="d-flex align-center mb-2">
                        <v-icon
                          small
                          class="mr-2"
                          >mdi-anchor</v-icon
                        >
                        <span> {{ vesselDetails.pol_terminal.name }} </span>
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-center"
                    >
                      <v-progress-linear
                        value="100"
                        color="primary"
                        height="4"
                      ></v-progress-linear>
                    </v-col>
                    <v-col
                      cols="4"
                      class="text-right"
                    >
                      <div class="d-flex align-center justify-end">
                        <v-icon
                          small
                          class="mr-2"
                          >mdi-map-marker</v-icon
                        >
                        <span> {{ vesselDetails.pod_terminal.name }} </span>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- Form nhập số điện thoại và email -->
                  <div class="mt-4">
                    <div style="display: flex; gap: 20px">
                      <div style="flex: 1; max-width: 530px">
                        <label
                          for="phoneNumber"
                          style="
                            font-size: 14px;
                            display: block;
                            margin-bottom: 4px;
                          "
                        >
                          Phone Number
                        </label>
                        <div
                          style="
                            position: relative;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <span
                            class="mdi mdi-phone"
                            style="
                              position: absolute;
                              left: 8px;
                              font-size: 20px;
                              color: #888;
                            "
                          ></span>
                          <input
                            id="phoneNumber"
                            type="tel"
                            v-model="phoneNumber"
                            required
                            style="
                              padding: 8px 8px 8px 36px;
                              width: 100%;
                              border: 1px solid #ccc;
                              border-radius: 4px;
                            "
                          />
                        </div>
                      </div>

                      <!-- Email Input -->
                      <div style="flex: 1; max-width: 530px">
                        <label
                          for="email"
                          style="
                            font-size: 14px;
                            display: block;
                            margin-bottom: 4px;
                          "
                        >
                          Email
                        </label>
                        <div
                          style="
                            position: relative;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <span
                            class="mdi mdi-email"
                            style="
                              position: absolute;
                              left: 8px;
                              font-size: 20px;
                              color: #888;
                            "
                          ></span>
                          <input
                            id="email"
                            type="input"
                            v-model="email"
                            required
                            style="
                              padding: 8px 8px 8px 36px;
                              width: 100%;
                              border: 1px solid #ccc;
                              border-radius: 4px;
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-right mt-4">
                    <div class="subtitle-1 font-weight-bold mb-2">
                      USD {{ price }}
                    </div>
                    <v-btn
                      color="primary"
                      :loading="isLoadingRequest"
                      @click="handleRequest"
                      >Request</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <!-- Rate Breakdown -->
              <v-card class="mb-6">
                <v-card-title>Rate breakdown</v-card-title>
                <v-card-text>
                  <v-timeline dense>
                    <v-timeline-item
                      small
                      fill-dot
                      color="primary"
                    >
                      <div class="d-flex justify-space-between">
                        <div>
                          <div class="font-weight-medium">{{ origin }}</div>
                          <div class="caption grey--text">Port</div>
                        </div>
                      </div>
                    </v-timeline-item>

                    <v-timeline-item
                      small
                      color="primary"
                    >
                      <div class="blue--text mb-2">
                        <v-icon
                          small
                          color="primary"
                          class="mr-1"
                          >mdi-ferry</v-icon
                        >
                        Sea FCL
                      </div>
                      <div class="caption">
                        {{ distance }} km, {{ transitDays }} days
                      </div>
                      <div class="caption">
                        Ocean Freight (FIFO) USD {{ oceanFreight }}
                      </div>
                    </v-timeline-item>

                    <v-timeline-item
                      small
                      fill-dot
                      color="primary"
                    >
                      <div class="d-flex justify-space-between">
                        <div>
                          <div class="font-weight-medium">
                            {{ destination }}
                          </div>
                          <div class="caption grey--text">Port</div>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>

              <!-- Validity -->
              <v-card class="mb-6">
                <v-card-title>Validity</v-card-title>
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon
                      small
                      class="mr-2"
                      >mdi-calendar</v-icon
                    >
                    <span>{{ validity }}</span>
                  </div>
                </v-card-text>
              </v-card>

              <!-- Departures -->
              <v-card>
                <v-card-title>Departures</v-card-title>
                <v-card-text class="pa-0 overflow-hidden">
                  <v-list style="max-width: 500px">
                    <v-list-item
                      v-for="departure in departures"
                      :key="departure.date"
                      class="overflow-hidden"
                      style="max-width: 500px"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="d-flex justify-space-between">
                            <div>
                              <strong>{{ departure.date }}</strong>
                              <v-icon
                                small
                                class="mx-2"
                                >mdi-ship</v-icon
                              >
                              {{ departure.vessel }}
                            </div>
                            <div class="grey--text">{{ departure.code }}</div>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- <v-btn
                    text
                    color="primary"
                    block
                    class="mt-2"
                  >
                    View more
                  </v-btn> -->
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <!-- Right Column - Map -->
              <v-card>
                <v-card-text>
                  <div class="map-container">
                    <iframe
                      width="100%"
                      height="100%"
                      style="border: 0; min-height: 500px"
                      loading="lazy"
                      allowfullscreen
                      :src="`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.6347957964826!2d105.8378493751975!3d21.00727158851638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac7802b8752b%3A0xca91cd914f429947!2zVOG7lW5nIGPDtG5nIHR5IEjDoG5nIEjhuqNpIFZp4buHdCBOYW0gLSBWSU1D!5e0!3m2!1svi!2s!4v1711503233131!5m2!1svi!2s`"
                    ></iframe>
                    <!-- <v-btn
                      color="primary"
                      text
                      block
                      class="mt-2"
                      :href="googleMapsUrl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in Google Maps
                    </v-btn> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState, mapMutations } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import { nextTick, ref } from 'vue';
import SmoothScroll from 'smooth-scroll';
import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';
import { message } from 'ant-design-vue';

export default {
  name: 'BookingDetailPageInfo',
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data() {
    return {
      tab: 1,
      quotationId: '123456',
      price: '2 288',
      carrier: 'MTT LIMBANG',
      status: 'Expired',
      containerSize: "20'ST",
      transitDays: 33,
      origin: 'Port Kelang, MY',
      destination: 'Calcutta, IN',
      distance: '2606.8',
      oceanFreight: '3,396',
      validity: '2025-01-01 - 2025-01-06',
      departures: [
        {
          date: '10 DEC, 2024',
          vessel: 'SOFIA I',
          code: '448W',
        },
        {
          date: '18 DEC, 2024',
          vessel: 'MAERSK CARONNE',
          code: '449W',
        },
        {
          date: '24 DEC, 2024',
          vessel: 'MAERSK BALTIMORE',
          code: '450W',
        },
      ],
      tableData: [],
      isLoading: true,
      fixedTop: false,
      scrollSettings: {}, // Mặc định không có thanh cuộn
      vesselDetails: null,
      phoneNumber: '',
      email: '',
      isLoadingRequest: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 100) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thay đổi thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) {
            requestAnimationFrame(scroll);
          }
        };

        requestAnimationFrame(scroll);
      };

      const bookingPageElement = document.getElementById(
        'carousel-item-caption'
      );
      if (bookingPageElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(bookingPageElement);
      }
    });

    // Gọi API khi trang tải lần đầu tiên
    this.fetchGetDetailVessel();

    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Kiểm tra kích thước ban đầu khi trang được tải
  },
  watch: {
    '$route.params.id': {
      handler(newVal) {
        if (newVal) {
          this.fetchGetDetailVessel();
        }
      },
      immediate: true, // Gọi ngay khi mounted() nếu đã có giá trị
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {},
  methods: {
    ...mapMutations('vsl/vesselSchedule', ['RESET']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchGetDetailVessel() {
      this.isLoading = true;
      try {
        this.id = this.$route.params.id;
        const res = await VesselScheduleApi.getVesselSchedule({
          code: this.id,
        });
        this.vesselDetails = res.data.data;
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleRowClick(record) {
      this.$router.push(`/booking/detail/1`);
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      // Khi màn hình nhỏ hơn 1367px, bật thanh cuộn
      if (screenWidth < 1367) {
        this.scrollSettings = { x: 1000 }; // Đặt chiều ngang cuộn
      } else {
        this.scrollSettings = {}; // Không cuộn nếu màn hình lớn hơn
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    // trừ ngày
    calculateDaysBetween(eta, etd) {
      // Dùng dayjs để chuyển đổi chuỗi ngày thành đối tượng dayjs và tính số ngày
      const etaDate = dayjs(eta);
      const etdDate = dayjs(etd);

      // Tính số ngày giữa eta và etd
      return etaDate.diff(etdDate, 'day');
    },
    async handleRequest() {
      if (this.phoneNumber === '' || this.email === '') {
        message.warning('Vui lòng điền đầy đủ thông tin.');
        return;
      }

      const data = {
        email: this.email,
        phone: this.phoneNumber,
        code: this.$route.params.id,
      };

      this.isLoadingRequest = true; // Bắt đầu loading

      try {
        // Tạo một Promise để timeout sau 60s
        const timeout = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 1)
        );
        await VesselScheduleApi.sendEmail(data);
        // Gửi request và đợi kết quả hoặc timeout
        // const res = await Promise.race([
        //   VesselScheduleApi.sendEmail(data),
        //   timeout,
        // ]);
        this.email = '';
        this.phoneNumber = '';
        message.success('Yêu cầu đã được gửi!');
        // if (res.status === 200) {
        //  message.success(`${res.data.message}`);
        //  this.email = '';
        //  this.phoneNumber = '';
        //   }
      } catch (error) {
        // if (error.message === 'Timeout') {
        //   message.error('Không gửi được: Yêu cầu tới server bị quá thời gian.');
        // } else {
        //   message.error(`${error.response?.data?.message || 'Đã xảy ra lỗi!'}`);
        // }
      } finally {
        this.isLoadingRequest = false;
      }
    },
  },
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
    // if (debit_search_no){
    //   this.tab = 2;
    // }
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
  .ant-table-thead > tr > th {
    background-color: #e8724a !important; /* Màu nền bạn muốn */
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-left: 1px solid #ffffff !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff !important;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #000000 !important;
    padding: 16px 0 16px 10px !important;
  }
  .ant-table-tbody > tr:hover {
    background: #f2f2f2 !important;
  }
  .align-center {
    text-align: center !important;
    padding: 0 !important;
  }
  .v-input__slot {
    width: 530px !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
}

#booking-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
}

#booking-page-table {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 80px;
}
.custom-input .v-input__control .v-input__underline {
  width: 50%; /* Thay đổi chiều dài gạch chân (tăng lên 150% */
  left: -25%; /* Để căn chỉnh lại vị trí gạch chân */
}
.container-booking {
  max-width: 1734px !important;
  margin-bottom: -35px;
  margin-left: 94px;
}

.container-booking-table {
  max-width: 1704px !important;
  margin-bottom: -35px;
  margin-top: -60px;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item a) {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
}

/* Thay đổi màu nền và màu chữ cho các mục trang */
:deep(.ant-pagination-item:hover) {
  border-color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item a:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
}

:deep(.ant-pagination-item-active) {
  border-color: #e8724a !important;
  font-weight: 500 !important;
}

:deep(.ant-pagination-item-active a) {
  color: #e8724a !important;
}

:deep(.ant-pagination-item-link:hover) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border-color: #e8724a !important;
}

:deep(.ant-pagination-item-link) {
  color: #e8724a !important; /* Đặt màu đường viền khi di chuột qua */
  border: none !important;
}

:deep(.ant-pagination-prev a) {
  color: #000000 !important;
}

:deep(.ant-pagination-next a) {
  color: #000000 !important;
}

:deep(
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon
  ) {
  color: red !important;
}

:deep(
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon
  ) {
  color: red !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  margin-bottom: 10px;
}

.loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.table-container .ant-table-body {
  overflow-x: hidden; /* Mặc định không cuộn */
}

@media screen and (max-width: 1367px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  .container-booking {
    max-width: 1734px !important;
    margin-bottom: -20px;
    margin-left: 12px;
  }
  :deep(.v-input__slot) {
    width: 375px !important;
  }
}
.search-bar {
  border-radius: 8px;
}

.search-field {
  margin-right: 8px;
}

.search-button {
  height: 40px;
  width: 40px;
}

.filter-card {
  border-radius: 8px;
}

.filter-title {
  font-size: 18px;
  font-weight: 500;
}

.clear-button {
  font-size: 14px;
}

.filter-subheader {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.filter-checkbox {
  margin-top: 4px;
  margin-bottom: 4px;
}

.filter-slider {
  margin-top: 16px;
  width: 200px !important;
}

.slider-labels {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  width: 200px;
}

.sort-card {
  border-radius: 8px;
}

.disclaimer {
  font-size: 14px;
}

.sort-select {
  max-width: 600px;
  border: none;
}

.custom-alert {
  border-left-width: 4px !important;
  border-color: #fb8c00 !important;
  background-color: #fff3e0 !important;
  color: #e65100 !important;
}

.route-card {
  border-radius: 8px;
}

.request-button {
  text-transform: none;
}

.quick-request-card {
  border-radius: 8px;
  background-color: #f5f5f5;
}

@media screen and (max-width: 1181px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 915px !important;
  }
}

@media screen and (max-width: 1025px) {
  .table-container .ant-table-body {
    overflow-x: auto; /* Hiển thị thanh cuộn trên tablet */
  }
  :deep(.px-2) {
    padding: 0 !important;
  }
  :deep(.pl-3) {
    padding: 0 !important;
  }
  :deep(.mb-4) {
    margin: 0 !important;
  }
  :deep(.mt-12) {
    margin: 0 !important;
  }
  :deep(.v-window-item) {
    padding: 0 10px !important;
  }
  :deep(.v-input__slot) {
    width: 680px !important;
  }
}
.v-timeline::before {
  background: #e0e0e0;
}

.v-timeline-item__dot--small {
  margin-left: 0;
}

.v-timeline-item__body {
  padding: 0 0 24px 24px;
}

.map-placeholder {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.map-container {
  position: relative;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  height: 0;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
