var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade carousel-slide",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc accelerating-digital-transformation.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                            attrs: { id: "carousel-item-caption" },
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Quote")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "quote-page", attrs: { id: "quote-page" } }, [
        _c(
          "div",
          { staticClass: "container container-quote" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c(
                      "label",
                      { staticClass: "required-label margin-label" },
                      [_vm._v("Base date")]
                    ),
                    _c(
                      "v-menu",
                      {
                        ref: "menu",
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "max-width": "290px",
                          "min-width": "290px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "date-picker-input specific-date-picker",
                                        attrs: {
                                          placeholder: "Select date",
                                          "prepend-icon": "mdi-calendar",
                                          readonly: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.baseDateFormatted,
                                          callback: function ($$v) {
                                            _vm.baseDateFormatted = $$v
                                          },
                                          expression: "baseDateFormatted",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.menu,
                          callback: function ($$v) {
                            _vm.menu = $$v
                          },
                          expression: "menu",
                        },
                      },
                      [
                        _c("v-date-picker", {
                          attrs: { "no-title": "" },
                          on: { change: _vm.saveAndClose },
                          model: {
                            value: _vm.baseDate,
                            callback: function ($$v) {
                              _vm.baseDate = $$v
                            },
                            expression: "baseDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "label" }, [_vm._v("POL")]),
                    _c("v-select", {
                      attrs: {
                        items: _vm.polOptions,
                        placeholder: "Select POL",
                        outlined: "",
                      },
                      model: {
                        value: _vm.pol,
                        callback: function ($$v) {
                          _vm.pol = $$v
                        },
                        expression: "pol",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "label" }, [_vm._v("POD")]),
                    _c("v-select", {
                      attrs: {
                        items: _vm.podOptions,
                        placeholder: "Select POD",
                        outlined: "",
                      },
                      model: {
                        value: _vm.pod,
                        callback: function ($$v) {
                          _vm.pod = $$v
                        },
                        expression: "pod",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "required-label" }, [
                      _vm._v("Charge type"),
                    ]),
                    _c("v-text-field", {
                      attrs: {
                        placeholder: "Search charge type",
                        outlined: "",
                      },
                      model: {
                        value: _vm.chargeType,
                        callback: function ($$v) {
                          _vm.chargeType = $$v
                        },
                        expression: "chargeType",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Container type"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        placeholder: "Select container type",
                        outlined: "",
                        items: _vm.containerTypeOptions,
                      },
                      model: {
                        value: _vm.containerType,
                        callback: function ($$v) {
                          _vm.containerType = $$v
                        },
                        expression: "containerType",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "label" }, [_vm._v("Tariff")]),
                    _c("v-text-field", {
                      attrs: { placeholder: "Search tariff", outlined: "" },
                      model: {
                        value: _vm.tariff,
                        callback: function ($$v) {
                          _vm.tariff = $$v
                        },
                        expression: "tariff",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Commodity"),
                    ]),
                    _c("v-text-field", {
                      attrs: { placeholder: "Search commodity", outlined: "" },
                      model: {
                        value: _vm.commodity,
                        callback: function ($$v) {
                          _vm.commodity = $$v
                        },
                        expression: "commodity",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-right mb-2", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "search-button",
                        on: { click: _vm.searchData },
                      },
                      [_vm._v(" Search ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "clear-button",
                        on: { click: _vm.clearData },
                      },
                      [_vm._v(" Clear ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("FooterHome"),
      _vm._m(0),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }