var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _c("div", {}, [
      _c(
        "div",
        { staticClass: "transx_footer_container transx_block_have_bg_image" },
        [
          _c("div", { staticClass: "transx_prefooter_container" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "transx_prefooter_wrapper transx_prefooter_type_1",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "widget footer_widget widget_transx_socials_widget",
                      attrs: { id: "transx_socials_widget-2" },
                    },
                    [
                      _c("div", { staticClass: "footer_widget_wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_socials_widget_wrapper content_align_left",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "transx_socials_widget_logo",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "flex-start",
                                },
                              },
                              [
                                _c("a", { attrs: { href: "#" } }, [
                                  _c("img", {
                                    staticStyle: {
                                      height: "50px",
                                      width: "auto",
                                      "-webkit-transition": "all 0.3s ease-out",
                                      transition: "all 0.3s ease-out",
                                      "padding-top": "-50px",
                                    },
                                    attrs: {
                                      src:
                                        this.$i18n.locale === "vi"
                                          ? require("../../../libhome/img/portal_logo.png")
                                          : require("../../../libhome/img/vimc-logo.png"),
                                      alt: "Footer Logo",
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._m(0),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "widget_text widget footer_widget widget_custom_html",
                      attrs: { id: "custom_html-3" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "widget_text footer_widget_wrapper" },
                        [
                          _c(
                            "h6",
                            { staticClass: "transx_footer_widget_title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.$i18n.locale === "en"
                                      ? "Headquarters"
                                      : "Trụ sở chính"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "textwidget custom-html-widget" },
                            [
                              _c(
                                "div",
                                { staticClass: "transx_offices_list" },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { "max-width": "200px" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("location")) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "widget_text widget footer_widget widget_custom_html",
                      attrs: { id: "custom_html-4" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "widget_text footer_widget_wrapper" },
                        [
                          _c(
                            "h6",
                            { staticClass: "transx_footer_widget_title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.$i18n.locale === "en"
                                      ? "Work Inquiries"
                                      : "Hợp tác công việc"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "textwidget custom-html-widget" },
                            [
                              _c(
                                "div",
                                { staticClass: "transx_offices_list" },
                                [
                                  _c("p", [
                                    _c("a", {
                                      staticClass: "transx_services_link",
                                      attrs: { href: "#" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          this.$i18n.locale === "en"
                                            ? "Interested in working with us?"
                                            : "Bạn quan tâm đến việc hợp tác <br/> với chúng tôi không?"
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._m(1),
                                  _c(
                                    "h6",
                                    {
                                      staticClass: "transx_footer_widget_title",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("phone")) + " "
                                      ),
                                    ]
                                  ),
                                  _vm._m(2),
                                  _vm._m(3),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "widget_text widget footer_widget widget_custom_html",
                      attrs: { id: "custom_html-5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "widget_text footer_widget_wrapper" },
                        [
                          _c(
                            "h6",
                            { staticClass: "transx_footer_widget_title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.$i18n.locale === "en"
                                      ? "Stay In Touch"
                                      : "Giữ liên lạc"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "textwidget custom-html-widget" },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "mc4wp-form mc4wp-form-28",
                                  attrs: {
                                    id: "mc4wp-form-1",
                                    method: "post",
                                    "data-id": "28",
                                    "data-name": "Subscribe",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mc4wp-form-fields" },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "email",
                                          name: "EMAIL",
                                          placeholder: "Email address",
                                          required: "",
                                        },
                                      }),
                                      _vm._m(4),
                                      _c("p", {
                                        staticClass:
                                          "transx_mailchimp_info_container",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            this.$i18n.locale === "en"
                                              ? "Stay tuned for our latest news"
                                              : "Hãy theo dõi tin tức mới nhất<br/> của chúng tôi"
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(5),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "_mc4wp_timestamp",
                                      value: "1719187575",
                                    },
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "_mc4wp_form_id",
                                      value: "28",
                                    },
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "_mc4wp_form_element_id",
                                      value: "mc4wp-form-1",
                                    },
                                  }),
                                  _c("div", { staticClass: "mc4wp-response" }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _c("footer", { staticClass: "transx_footer footer_type_3" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "transx_footer_3_wrapper" }, [
                _c("div", { staticClass: "row flex-sm-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-12 col-lg-9 col-xl-8 top-20 top-sm-0 text-center text-sm-left",
                    },
                    [
                      _c("div", { staticClass: "transx_footer_menu_2_cont" }, [
                        _c(
                          "ul",
                          {
                            staticClass: "transx_footer_menu_2",
                            attrs: { id: "menu-footer-menu-2" },
                          },
                          [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "menu-item menu-item-type-post_type menu-item-object-page menu-item-103",
                                attrs: { id: "menu-item-103" },
                              },
                              [
                                _c("a", { attrs: { href: "/" } }, [
                                  _vm._v(_vm._s(_vm.$t("home"))),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "menu-item menu-item-type-post_type menu-item-object-page menu-item-106",
                                attrs: { id: "menu-item-106" },
                              },
                              [
                                _c("a", { attrs: { href: "/blogs" } }, [
                                  _vm._v(_vm._s(_vm.$t("blogs"))),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "menu-item menu-item-type-post_type menu-item-object-page menu-item-105",
                                attrs: { id: "menu-item-105" },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: "/vesselSchedule" } },
                                  [_vm._v(_vm._s(_vm.$t("services")))]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "menu-item menu-item-type-post_type menu-item-object-page menu-item-108",
                                attrs: { id: "menu-item-108" },
                              },
                              [
                                _c("a", { attrs: { href: "/contact" } }, [
                                  _vm._v(_vm._s(_vm.$t("contact"))),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "menu-item menu-item-type-post_type menu-item-object-page menu-item-107",
                                attrs: { id: "menu-item-107" },
                              },
                              [
                                _c("a", { attrs: { href: "/recruitment" } }, [
                                  _vm._v(_vm._s(_vm.$t("recruitment"))),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", {
                    staticClass:
                      "col-sm-12 col-lg-3 col-xl-4 top-20 top-sm-0 text-center text-sm-right",
                  }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "transx-footer-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("a", { attrs: { href: "mailto:vimclines@vimc-lines.com" } }, [
        _vm._v("vimclines@vimc-lines.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [_c("a", { attrs: { href: "" } }, [_vm._v("02435770894")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("a", { attrs: { href: "" } }, [_vm._v("(+84) 2435770894")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "transx_footer_submit_container" }, [
      _c("input", { attrs: { type: "submit", value: "" } }),
      _c("span", { staticClass: "icon" }, [
        _c("img", {
          attrs: {
            src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEUlEQVR4nO2UPUoEQRSEBzFbEWP/8Aoq3sFFM3NDr+Ap/AEDD7GCIt5C9ArKrJobGEx/BSVoNw6zMzvrrorBFkzSPV1fvfeazrKp/o2AHeBJkif5gD7QrQP0JzXXFyQfAKRN2/PjdsF2J/nUVfAa6c/A3nfNgV3gMXq81dEXJfVKpV7bXh0h9ZKki9K5G9trbcN+SEkkHdqerTGekXRQqvxF0v4w43PbC/FwBzgCiKnuQgib6d+iKDYk3UZjAWdpdrbngOMBQEpRXiuKYr1idAqcfHI/wPchhK1K0Lx2yE3Td6UVba1rvEWNG1G2lyVdSrqyvZI1aGzAqJoCWjXsqfjdxw7o/gQEyIHt9lqn+iu9AzTRtcTbJEAhAAAAAElFTkSuQmCC",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", { staticStyle: { display: "none !important" } }, [
      _vm._v("Leave this field empty if you're human: "),
      _c("input", {
        attrs: {
          type: "text",
          name: "_mc4wp_honeypot",
          value: "",
          tabindex: "-1",
          autocomplete: "off",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }