<template class="pa-0 background">
  <v-navigation-drawer
    v-model="drawer"
    app
    overflow
    color="background"
  >
    <template v-slot:prepend>
      <div class="mx-2 mt-8 mb-8">
        <v-img
          src="/assets/img/logo.png"
          height="40px"
          contain
        />
      </div>
    </template>

    <v-list
      nav
      dense
      v-if="currentApp === 'shipping' || this.$vuetify.breakpoint.mobile"
    >
      <template v-for="(menu, mIndex) of vslMenu">
        <v-subheader
          :key="'subheader-' + mIndex"
          v-if="!Array.isArray(menu)"
          >{{ menu.title }}</v-subheader
        >
        <v-list-item-group
          :key="'menu-' + mIndex"
          v-model="vslMenuIndex"
          color="#3973d4"
          v-else
        >
          <v-list-item
            class="rounded-pill"
            @click="$vuetify.theme.themes.light.primary = '#3973d4'"
            v-for="(item, i) of menu"
            :key="i"
            :to="{ path: '/' + item.path }"
            :disabled="!item.path"
          >
            <v-list-item-icon>
              <v-icon
                color="#3973d4"
                v-text="item.icon"
                right
                :disabled="!item.path"
              ></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-list>

    <v-list
      nav
      dense
      v-if="currentApp === 'port' || $vuetify.breakpoint.mobile"
    >
      <v-subheader>{{ $t('title-port-management') }}</v-subheader>
      <v-list-item-group
        v-model="vplMenuIndex"
        color="#ce4900"
      >
        <v-list-item
          @click="$vuetify.theme.themes.light.primary = '#ce4900'"
          class="rounded-pill"
          v-for="(item, i) in vplMenu"
          :key="i"
          :to="{ path: '/' + item.path }"
        >
          <v-list-item-icon>
            <v-icon
              color="#ce4900"
              v-text="item.icon"
              right
            ></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <div
          class="overline text-center"
          style="font-size: 8px !important; line-height: initial"
        >
          {{ version }}
        </div>
        <ChangeLanguageButton class="my-2" />
        <v-btn
          class="mb-4"
          block
          small
          depressed
          @click="onLogout()"
        >
          <v-icon left>mdi-power</v-icon>
          {{ $t('logout') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import pjson from '../../../package.json';
import ChangeLanguageButton from '@/components/shared/local/base/ChangeLanguageButton.vue';

export default {
  components: { ChangeLanguageButton },
  data: () => ({
    vslMenuIndex: 0,

    vplMenuIndex: 0,
  }),
  computed: {
    ...mapState('user', ['myInfo']),
    version() {
      return `v${pjson.version}`;
    },
    currentApp() {
      if (this.$route.matched[1].path === '/app/shipping') {
        return 'shipping';
      }
      if (this.$route.matched[1].path === '/app/port') {
        return 'port';
      }
      return null;
    },
    drawer: {
      get() {
        return this.$store.state.ui.drawer;
      },
      set(value) {
        return this.TOGGLE_DRAWER(value);
      },
    },
    vslMenu() {
      const menu = [
        {
          title: this.$t('title-lookup'),
        },
        [
          {
            title: this.$t('title-request-a-quote'),
            icon: 'mdi-send',
            path: 'app/shipping/request-a-quote',
          },

          {
            title: this.$t('title-tracking'),
            icon: 'mdi-cube',
            path: 'app/shipping/container-tracking',
          },
          ...(this.myInfo.merchantCodes && this.myInfo.merchantCodes.length > 0
            ? []
            : [
                {
                  title: this.$t('title-debit-search'),
                  icon: 'mdi-file-search',
                  path: 'app/shipping/debit-search',
                },
                {
                  title: this.$t('title-container-release-order-search'),
                  icon: 'mdi-clipboard-text-search',
                  path: 'app/shipping/container-release-order-search',
                },
              ]),
        ],
        {
          title: this.$t('title-my-account'),
        },
        [
          {
            title: this.$t('title-quotation-list'),
            icon: 'mdi-cards',
            path: 'app/shipping/my-quotation',
          },
          ...(this.myInfo.merchantCodes && this.myInfo.merchantCodes.length > 0
            ? [
                {
                  title: this.$t('title-short-my-debit'),
                  icon: 'mdi-file',
                  path: 'app/shipping/my-debit',
                },
                {
                  title: this.$t('title-short-my-container-release-order'),
                  icon: 'mdi-clipboard-text',
                  path: 'app/shipping/my-container-release-order',
                },
                {
                  title: this.$t('title-short-my-bill-lading'),
                  icon: 'mdi-text-box-multiple',
                  path: 'app/shipping/my-bill-lading',
                },
                // {
                //   title: this.$t('title-short-my-booking-note'),
                //   icon: 'mdi-file-document-edit',
                // },
                {
                  title: this.$t('title-short-my-container-delivery-order'),
                  icon: 'mdi-file-document-multiple',
                  path: 'app/shipping/my-container-delivery-order',
                },
              ]
            : []),
        ],
      ];
      return menu;
    },
    vplMenu() {
      const menu = [
        {
          title: this.$t('title-order-list'),
          icon: 'mdi-hexagon-multiple',
          path: 'app/port/my-order',
        },
      ];
      return menu;
    },
  },
  created() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    } else {
      this.drawer = true;
    }
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DRAWER']),
    ...mapActions('auth', ['logout']),
    async onLogout() {
      localStorage.removeItem('accessToken');
      window.location.reload();
    },
    onViewTutorial() {
      window.location.href = process.env.VUE_APP_DOMAIN;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-list--nav .v-list-item:before {
    border-radius: 9999px;
  }
  .v-navigation-drawer__border {
    background-color: unset !important;
  }
}
</style>
