var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "about" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/about-us.jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.$i18n.locale === "en"
                                      ? "About Us"
                                      : "Giới thiệu"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "about" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "elementor-about" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "transx_image_box_info" }, [
                    _vm._v(" " + _vm._s(_vm.$t("fastThan"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("youCanImage")) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "section-header text-left",
                    attrs: { id: "about-us-page" },
                  },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$t("aboutUs")))]),
                    _c(
                      "h2",
                      {
                        staticStyle: {
                          "text-align": "left",
                          "font-size": "40px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("aboutUsTitlePara")) + " ")]
                    ),
                  ]
                ),
                _c("div", { staticClass: "about-content" }, [
                  _c("p", { staticStyle: { "text-align": "justify" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("aboutUsContentPara")) + " "),
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("aboutNameVn")) + " "),
                    ]),
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("aboutNameEn")) + " "),
                    ]),
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("aboutNameInt")) + " "),
                    ]),
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("aboutAddress")) + " "),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "section",
        {
          staticClass:
            "elementor-section-mission elementor-top-section elementor-element elementor-element-1c2a3537 elementor-reverse-tablet elementor-reverse-mobile elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default",
          attrs: {
            "data-id": "1c2a3537",
            "data-element_type": "section",
            "data-settings":
              '{"stretch_section":"section-stretched","background_background":"classic"}',
          },
        },
        [
          _c(
            "div",
            { staticClass: "elementor-container elementor-column-gap-default" },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("v-row", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d1e3b3c",
                        attrs: {
                          "data-id": "d1e3b3c",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap-mission elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-5e96f61c elementor-widget elementor-widget-transx_heading",
                                attrs: {
                                  "data-id": "5e96f61c",
                                  "data-element_type": "widget",
                                  "data-widget_type": "transx_heading.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "transx_heading_widget" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "transx_up_heading",
                                            attrs: { id: "mission" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("mission")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "h2",
                                          { staticClass: "transx_heading" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("aboutMissionTitle1")
                                                ) +
                                                " "
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("aboutMissionTitle2")
                                                )
                                              ),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("aboutMissionTitle3")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-564e59c4 elementor-widget elementor-widget-text-editor",
                                attrs: {
                                  "data-id": "564e59c4",
                                  "data-element_type": "widget",
                                  "data-widget_type": "text-editor.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "text-align": "justify",
                                        },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("aboutMissionParaTitle1")
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(_vm.$t("aboutMissionPara1")) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("aboutMissionParaTitle2")
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(_vm.$t("aboutMissionPara2")) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("aboutMissionParaTitle3")
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("aboutMissionPara3")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "section",
                              {
                                staticClass:
                                  "elementor-section elementor-inner-section elementor-element elementor-element-3b2d22f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                attrs: {
                                  "data-id": "3b2d22f",
                                  "data-element_type": "section",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "margin-top": "-50px",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _c("strong", [_vm._v("VIMC LINES")]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementor-container elementor-column-gap-no",
                                    staticStyle: { visibility: "hidden" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e8a9518",
                                        attrs: {
                                          "data-id": "e8a9518",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-widget-wrap elementor-element-populated",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementor-element elementor-element-1895d5b5 elementor-widget elementor-widget-image",
                                                attrs: {
                                                  "data-id": "1895d5b5",
                                                  "data-element_type": "widget",
                                                  "data-widget_type":
                                                    "image.default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-widget-container",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "attachment-large size-large",
                                                      staticStyle: {
                                                        width: "100%",
                                                        height: "auto",
                                                      },
                                                      attrs: {
                                                        src: require("../../../libhome/img/mission-2.jpg"),
                                                        alt: "",
                                                        loading: "lazy",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-25ab5b88",
                                        attrs: {
                                          "data-id": "25ab5b88",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              attrs: {
                                                "data-id": "264ebb21",
                                                "data-element_type": "widget",
                                                "data-widget_type":
                                                  "text-editor.default",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementor-widget-container",
                                                },
                                                [
                                                  _c("p", [
                                                    _c("strong", [
                                                      _vm._v("VIMC LINES"),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-64dd5eb9",
                        attrs: {
                          "data-id": "64dd5eb9",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-3c3c2843 elementor-widget elementor-widget-image",
                                attrs: {
                                  "data-id": "3c3c2843",
                                  "data-element_type": "widget",
                                  "data-widget_type": "image.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "elementor-widget-container",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center",
                                      "padding-top": "100px",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "attachment-large size-large",
                                      staticStyle: {
                                        width: "471px",
                                        height: "700px",
                                        "object-fit": "contain",
                                      },
                                      attrs: {
                                        src: require("../../../libhome/img/vimcngang.jpg"),
                                        alt: "",
                                        loading: "lazy",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "timeline-1",
          staticStyle: { "background-color": "#ffffff" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "transx_timeline_heading_container" }, [
              _c(
                "div",
                { staticClass: "transx_up_heading", attrs: { id: "history" } },
                [_vm._v(" " + _vm._s(_vm.$t("history")) + " ")]
              ),
              _c("h2", { staticClass: "transx_heading_history" }, [
                _vm._v(_vm._s(_vm.$t("historyTitle"))),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "container1" },
            [
              _c(
                "swiper",
                { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
                [
                  _vm._l(_vm.loopedItems, function (item, index) {
                    return _c("swiper-slide", { key: index }, [
                      _c("div", { staticClass: "timeline-wrapper" }, [
                        _c("div", { staticClass: "middle-line" }),
                        _c("div", { staticClass: "box box-bottom" }, [
                          _c("div", { staticClass: "box-content-top2" }, [
                            _c("p", { staticClass: "transx_timeline_date" }, [
                              _vm._v(" " + _vm._s(item.year) + " "),
                              _c(
                                "span",
                                { staticStyle: { "font-size": "22px" } },
                                [_vm._v("year")]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "date" }),
                          _c("div", { staticClass: "box-content" }, [
                            _c("p", [_vm._v(_vm._s(item.content))]),
                          ]),
                        ]),
                      ]),
                    ])
                  }),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "swiper-button-prev" }),
              _c("div", { staticClass: "swiper-button-next" }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "team" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass: "section-header text-center",
              attrs: { id: "our-team" },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("meetOurTeam")))]),
              _c("h2", [_vm._v(_vm._s(_vm.$t("ourEngineer")))]),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c("div", {}, [
                _c("div", { staticClass: "team-item" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "team-text" }, [
                    _c("h2", { style: _vm.computedFontSize }, [
                      _vm._v(_vm._s(_vm.$t("CEO"))),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                    _vm._m(2),
                  ]),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(3),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CEO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                  _vm._m(4),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(5),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CFO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                  _vm._m(6),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(7),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(" " + _vm._s(_vm.$t("CHRO")) + " "),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("worker")))]),
                  _vm._m(8),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(9),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CTO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("worker")))]),
                  _vm._m(10),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "blog" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass: "section-header text-center",
              attrs: { id: "our-blog" },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("ourBlog")))]),
              _c("h2", [_vm._v(_vm._s(_vm.$t("new&article")))]),
            ]
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "blog-item" }, [
                _c("div", { staticClass: "blog-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../libhome/img/tòa nhà ocean park - trụ sở tổng công ty hàng hải việt nam(vimc).jpg"),
                      alt: "Image",
                    },
                  }),
                  _c("div", { staticClass: "meta-date" }, [
                    _c("span", [_vm._v("01")]),
                    _c("strong", [_vm._v(_vm._s(_vm.$t("apr")))]),
                    _c("span", [_vm._v("2024")]),
                  ]),
                ]),
                _c("div", { staticClass: "blog-text" }, [
                  _c("h3", [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                          overflow: "hidden",
                        },
                        attrs: { href: "/blogs/detail/1" },
                      },
                      [_vm._v(_vm._s(_vm.$t("blogtTitle1")))]
                    ),
                  ]),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-line-clamp": "4",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("blogtContent1")) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "blog-meta" }, [
                  _vm._m(11),
                  _c("p", [
                    _c("i", { staticClass: "fa fa-folder" }),
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v(_vm._s(_vm.$t("establishment"))),
                    ]),
                  ]),
                  _vm._m(12),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "blog-item" }, [
                _c("div", { staticClass: "blog-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../libhome/img/thuyenvien.jpg"),
                      alt: "Image",
                    },
                  }),
                  _c("div", { staticClass: "meta-date" }, [
                    _c("span", [_vm._v("25")]),
                    _c("strong", [_vm._v(_vm._s(_vm.$t("jun")))]),
                    _c("span", [_vm._v("2024")]),
                  ]),
                ]),
                _c("div", { staticClass: "blog-text" }, [
                  _c("h3", [
                    _c("a", { attrs: { href: "/blogs/detail/2" } }, [
                      _vm._v(_vm._s(_vm.$t("blogtTitle2"))),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("blogtContent2")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "blog-meta" }, [
                  _vm._m(13),
                  _c("p", [
                    _c("i", { staticClass: "fa fa-folder" }),
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v(_vm._s(_vm.$t("seafarer"))),
                    ]),
                  ]),
                  _vm._m(14),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "blog-item" }, [
                _c("div", { staticClass: "blog-img" }, [
                  _c("img", {
                    staticStyle: {
                      width: "363px",
                      height: "235.7",
                      "object-fit": "contain",
                    },
                    attrs: {
                      src: require("../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"),
                      alt: "Image",
                    },
                  }),
                  _c("div", { staticClass: "meta-date" }, [
                    _c("span", [_vm._v("13")]),
                    _c("strong", [_vm._v(_vm._s(_vm.$t("jun")))]),
                    _c("span", [_vm._v("2024")]),
                  ]),
                ]),
                _c("div", { staticClass: "blog-text" }, [
                  _c("h3", [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                          overflow: "hidden",
                        },
                        attrs: { href: "/blogs/detail/3" },
                      },
                      [_vm._v(_vm._s(_vm.$t("blogtTitle3")))]
                    ),
                  ]),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "-webkit-box",
                        "-webkit-line-clamp": "4",
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("blogtContent3")) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "blog-meta" }, [
                  _vm._m(15),
                  _c("p", [
                    _c("i", { staticClass: "fa fa-folder" }),
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v(_vm._s(_vm.$t("terminal"))),
                    ]),
                  ]),
                  _vm._m(16),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("FooterHome"),
      _vm._m(17),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about-img" }, [
      _c("img", {
        staticStyle: { width: "520px" },
        attrs: {
          src: require("../../../libhome/img/anhngangvimc.png"),
          alt: "Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-1.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-facebook-f" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-linkedin-in" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-1.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-facebook-f" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-linkedin-in" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-2.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-facebook-f" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-linkedin-in" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-3.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-facebook-f" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-linkedin-in" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-4.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-facebook-f" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-linkedin-in" }),
      ]),
      _c("a", { attrs: { href: "" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-user" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Vimc Lines")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-comments" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Views")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-user" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Vimc Lines")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-comments" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Views")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-user" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Vimc Lines")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "fa fa-comments" }),
      _c("a", { attrs: { href: "" } }, [_vm._v("Views")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }