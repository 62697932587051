<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="recruitment" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/Cảng Hải Phòng - .jpg"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('recruitment') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->

    <div
      class="transx_page_content_wrapper"
      style="background-color: #ffffff"
    >
      <!-- About Start -->
      <div class="recruitment">
        <div class="container">
          <div class="row align-items-center">
            <section class="recruitment-list">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2
                      class="label"
                      id="recruitment-1"
                    >
                      {{ $t('recPosition') }}
                    </h2>
                    <div class="search-job">
                      <form
                        action=""
                        name="search-job"
                        method=""
                      >
                        <input
                          type="text"
                          id="search"
                          data-url="#"
                          placeholder="Search position"
                        />
                        <button><i class="fa fa-search"></i></button>
                      </form>
                      <ul id="seach-result"></ul>
                    </div>
                    <div class="list-jobs">
                      <ul
                        class="nav nav-tabs"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link-recruitment active"
                            id="all-tab"
                            data-toggle="tab"
                            href="#all"
                            role="tab"
                            aria-controls="all"
                            aria-selected="true"
                            >{{ $t('all') }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link-recruitment"
                            id="khoi-cong-nghe-tab"
                            data-toggle="tab"
                            href="#khoi-cong-nghe"
                            role="tab"
                            aria-controls="khoi-cong-nghe"
                            aria-selected="true"
                            >{{ $t('bussDepart') }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link-recruitment"
                            id="khoi-kinh-doanh-tab"
                            data-toggle="tab"
                            href="#khoi-kinh-doanh"
                            role="tab"
                            aria-controls="khoi-kinh-doanh"
                            aria-selected="true"
                            >{{ $t('acountDepart') }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link-recruitment"
                            id="khoi-van-phong-tab"
                            data-toggle="tab"
                            href="#khoi-van-phong"
                            role="tab"
                            aria-controls="khoi-van-phong"
                            aria-selected="true"
                            >{{ $t('itDepart') }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link-recruitment"
                            id="khoi-nhan-su-tab"
                            data-toggle="tab"
                            href="#khoi-nhan-su"
                            role="tab"
                            aria-controls="khoi-nhan-su"
                            aria-selected="true"
                            >{{ $t('HRD') }}
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="all"
                        >
                          <div class="scroll">
                            <div class="row">
                              <div
                                class="col-12 col-md-6 recruitment-item"
                                style="display: block"
                              >
                                <a href="/recruitment/detail/1">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('bussEmployee') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-8-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{
                                            this.$i18n.locale == 'en'
                                              ? 'Hanoi, Hai Phong, Ho Chi Minh'
                                              : `Hà Nội, Hải Phòng,
                                             Hồ Chí Minh`
                                          }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div
                                class="col-12 col-md-6 recruitment-item"
                                style="display: block"
                              >
                                <a href="/recruitment/detail/2">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('genAccountEmploy') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-8-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{ $t('location') }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div
                                class="col-12 col-md-6 recruitment-item"
                                style="display: block"
                              >
                                <a href="/recruitment/detail/3">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('itEmployee') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-8-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{ $t('location') }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div class="col-12 col-md-6 recruitment-item">
                                <a href="/recruitment/detail/4">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('techEngineer') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-7-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{ $t('location') }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div class="col-12 col-md-6 recruitment-item">
                                <a href="/recruitment/detail/5">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('HRC') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-8-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{ $t('location') }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div class="col-12 col-md-6 recruitment-item">
                                <a href="/recruitment/detail/6">
                                  <div class="job-item">
                                    <p
                                      class="name"
                                      style="
                                        color: #000000;
                                        font-weight: 700;
                                        font-size: 15px;
                                      "
                                    >
                                      {{ $t('AO-VME') }}
                                    </p>
                                    <div class="row">
                                      <div class="col-5">
                                        <p class="deadline">
                                          <i class="fa fa-calendar"></i>
                                          15-8-2024
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="location-recruitment">
                                          <img
                                            src="../../../libhome/img/location.svg"
                                          />
                                          {{ $t('location') }}
                                        </p>
                                        <div style="padding-bottom: 10px"></div>
                                      </div>
                                      <div class="col-6">
                                        <p class="salary">
                                          <img
                                            src="../../../libhome/img/salary.svg"
                                          />
                                          {{ $t('agreement') }}
                                        </p>
                                        <div style="padding-bottom: 5px"></div>
                                        <p class="worktime">
                                          <i class="fa fa-clock"></i> 8h00 -
                                          17h00
                                        </p>
                                      </div>
                                      <div class="col-1 pr-0">
                                        <span class="fa fa-angle-right"></span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <button
                            class="watch-more"
                            id="watchMore"
                            style="visibility: hidden"
                          >
                            <a
                              href="#"
                              style="color: black"
                              >Show more</a
                            >
                          </button>
                          <button
                            class="watch-more"
                            id="close"
                            style="display: none"
                          >
                            <a href="#">Thu gọn</a>
                          </button>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="khoi-cong-nghe"
                        >
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/1">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('bussEmployee') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-8-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{
                                          this.$i18n.locale == 'en'
                                            ? 'Hanoi, Hai Phong, Ho Chi Minh'
                                            : `Hà Nội, Hải Phòng,
                                             Hồ Chí Minh`
                                        }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="khoi-kinh-doanh"
                        >
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/2">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('genAccountEmploy') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-8-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{ $t('location') }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/6">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('AO-VME') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-8-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{ $t('location') }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="khoi-van-phong"
                        >
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/3">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('itEmployee') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-8-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{ $t('location') }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/4">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('techEngineer') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-07-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{ $t('location') }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="khoi-nhan-su"
                        >
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <a href="/recruitment/detail/5">
                                <div class="job-item">
                                  <p
                                    class="name"
                                    style="
                                      color: #000000;
                                      font-weight: 700;
                                      font-size: 15px;
                                    "
                                  >
                                    {{ $t('HRC') }}
                                  </p>
                                  <div class="row">
                                    <div class="col-5">
                                      <p class="deadline">
                                        <i class="fa fa-calendar"></i>
                                        15-8-2024
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="location-recruitment">
                                        <img
                                          src="../../../libhome/img/location.svg"
                                        />
                                        {{ $t('location') }}
                                      </p>
                                      <div style="padding-bottom: 10px"></div>
                                    </div>
                                    <div class="col-6">
                                      <p class="salary">
                                        <img
                                          src="../../../libhome/img/salary.svg"
                                        />
                                        {{ $t('agreement') }}
                                      </p>
                                      <div style="padding-bottom: 5px"></div>
                                      <p class="worktime">
                                        <i class="fa fa-clock"></i> 8h00 - 17h00
                                      </p>
                                    </div>
                                    <div class="col-1 pr-0">
                                      <span class="fa fa-angle-right"></span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="image-library">
                      <div
                        class="label"
                        id="recruitment-2"
                      >
                        <h2>{{ $t('libPhoto') }}</h2>
                        <div
                          class="images"
                          style="
                            padding: 10px 10px 20px 10px;
                            width: 1167px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <div class="row">
                            <div class="col-3 p-0">
                              <div class="d-flex flex-wrap h-100">
                                <img
                                  src="https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24.jpg"
                                  class="mb-2"
                                  alt="Image Item"
                                />
                                <img
                                  src="https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-5.jpg"
                                  alt="Image Item"
                                />
                              </div>
                            </div>
                            <div class="col-6 pr-2 pl-2">
                              <img
                                class="h-100"
                                src="https://vimc.co/wp-content/uploads/2024/01/VIMC_Logistics-Hai-Duong.jpg"
                                alt="Image Item"
                              />
                            </div>
                            <div class="col-3 p-0">
                              <div class="d-flex flex-wrap h-100">
                                <img
                                  src="https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-3.jpg"
                                  class="mb-2"
                                  alt="Image Item"
                                />
                                <img
                                  src="https://vimc.co/wp-content/uploads/2023/01/VIMC_tau-1.23.jpg"
                                  alt="Image Item"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="curriculum-vitae"
                      id="recruitment-3"
                    >
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="text">
                            <p
                              class="label"
                              ref="apply"
                              v-html="$t('recApply')"
                            ></p>
                            <p class="desc">
                              {{ $t('reacContent1') }}
                            </p>
                            <p class="desc">
                              {{ $t('reacContent2') }}
                            </p>
                            <p
                              class="support-line"
                              v-html="$t('reacContent3')"
                            ></p>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form">
                            <form
                              @submit.prevent="sendEmail"
                              ref="form"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                value="tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11"
                              />
                              <input
                                type="password"
                                name="password"
                                value=""
                                hidden
                              />
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  :placeholder="
                                    this.$i18n.locale === 'en'
                                      ? 'Full Name'
                                      : 'Họ và tên'
                                  "
                                  v-model="form.name"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  placeholder="Email"
                                  v-model="form.email"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="phone"
                                  :placeholder="$t('phone')"
                                  v-model="form.phone"
                                />
                              </div>
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  name="recruitment_id"
                                  :placeholder="$t('recPosition')"
                                  v-model="form.position"
                                >
                                  <option
                                    value=""
                                    disabled
                                    selected
                                  >
                                    {{
                                      this.$i18n.locale === 'en'
                                        ? 'Apply for the position'
                                        : 'Ứng tuyển vào vị trí này'
                                    }}
                                  </option>
                                  <option :value="$t('bussDepart')">
                                    {{ $t('bussDepart') }}
                                  </option>
                                  <option :value="$t('acountDepart')">
                                    {{ $t('acountDepart') }}
                                  </option>
                                  <option :value="$t('itDepart')">
                                    {{ $t('itDepart') }}
                                  </option>
                                  <option :value="$t('HRD')">
                                    {{ $t('HRD') }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="d-flex justify-content-between sp-column"
                              >
                                <label
                                  for="upload_cv"
                                  class="add-cv"
                                  ><i class="fa fa-file"></i
                                  >{{ $t('uploadCV') }}</label
                                >
                                <input
                                  type="file"
                                  name="cv"
                                  id="upload_cv"
                                  @change="handleFileUpload"
                                  v-show="!isHidden"
                                />

                                <button
                                  type="submit"
                                  class="send"
                                  :disabled="isLoading"
                                >
                                  <span v-if="isLoading">
                                    <i class="fa fa-spinner fa-spin"></i>
                                    {{ $t('sendCV') }}
                                    <!-- Icon loading -->
                                  </span>
                                  <span v-else>
                                    {{ $t('sendCV') }}
                                  </span>
                                </button>
                              </div>
                              <span
                                v-if="selectedFile"
                                style="
                                  display: flex;
                                  margin: 5px 0 0 20px;
                                  font-size: 16px;
                                  color: #fff;
                                "
                                >{{ selectedFile.name }}</span
                              >
                              <div class="alert"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- About End -->
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        position: '',
      },
      attachment: null,
      isHidden: true,
      selectedFile: null,
      isLoading: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.attachment = event.target.files[0];
    },
    async sendEmail() {
      this.isLoading = true;

      // Validate form fields
      if (!this.form.name) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your full name'
            : 'Vui lòng nhập họ và tên'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.email) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your email'
            : 'Vui lòng nhập email'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.phone) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your phone number'
            : 'Vui lòng nhập số điện thoại'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.position) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please select the position'
            : 'Vui lòng nhập vị trí'
        );
        this.isLoading = false;
        return;
      }

      if (!this.selectedFile) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please upload your CV'
            : 'Vui lòng nhập CV'
        );
        this.isLoading = false;
        return;
      }

      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('from', this.form.email);
      formData.append('position', this.form.position);
      formData.append('phone', this.form.phone);
      formData.append('cv', this.attachment);

      try {
        const response = await fetch(
          'https://sendmail-tl91.onrender.com/send-email1',
          {
            method: 'POST',
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        this.$toast.success(
          `${
            this.$i18n.locale === 'en'
              ? 'Email sent successfully'
              : 'Email đã được gửi'
          }`
        );
        // this.$refs.form.reset();
        this.form.name = '';
        this.form.email = '';
        this.form.phone = '';
        this.form.position = '';
        this.selectedFile = null;
      } catch (error) {
        this.$toast.error(
          `${
            this.$i18n.locale === 'en'
              ? 'Error when sending email'
              : 'Có lỗi khi gửi email'
          }`
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});

/**
 * header
 */
// window.addEventListener('scroll', function() {
//   const navbar = document.getElementsByClassName('headerN');
//   if (window.scrollY > 0) {
//     navbar.classList.add('scrolled');
//   } else {
//     navbar.classList.remove('scrolled');
//   }
// });
</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-caption {
  bottom: 45px !important;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

/*
anchors are inline so you need ot make them block to go full width
*/
.carousel-item a {
  display: block;
  width: 100%;
}
/*  */
.transx_content_slide_wrapper {
  text-align: left;
  letter-spacing: 0;
  /* font-family: Inter, sans-serif; */
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #696e76;
  hyphens: manual;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: center;
}

.transx_up_heading_overlay {
  hyphens: manual;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  box-sizing: border-box;
  line-height: 0.85;
  font-weight: 700;
  color: transparent;
  user-select: none;
  position: absolute;
  z-index: 1;
  display: initial;
  /* font-family: Inter, 'sans-serif'; */
  font-size: 200px;
  letter-spacing: -0.06em;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.35);
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
}

.transx_content_slider_title {
  text-align: center;
  letter-spacing: -0.03em;
  word-wrap: break-word;
  margin: 0 0 0.5rem;
  /* font-family: Inter, sans-serif; */
  font-weight: 700;
  text-transform: none;
  font-style: normal;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  font-size: 60px;
  line-height: 60px;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_container {
  width: 60px;
  height: 60px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 19px;
  border: 1px solid #005bac;
}

.transx_icon_box_item.transx_view_type_3 .transx_icon_box_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #767f7f;
}

.transx_icon_box_item .transx_info_container .transx_icon_box_socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.transx_icon_box_item.transx_view_type_3
  .transx_info_container
  .transx_icon_box_socials
  li:not(:last-of-type) {
  margin: 0 18px 0 0;
}

.elementor-widget-counter.transx_counter_align_left
  .elementor-counter-number-wrapper,
body
  .elementor-widget-counter.transx_counter_align_right
  .elementor-counter-number-wrapper {
  display: block;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  font-size: 42px;
  line-height: 1.1;
  letter-spacing: -0.05em;
  font-weight: 700;
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  color: #005bac;
}

.elementor-widget-counter
  .elementor-counter-number-wrapper
  .elementor-counter-number {
  font-size: 60px;
}

.timeline-wrapper .middle-line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 30%;
  transform: translateY(-50%);
  background: #d9d9d9;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}
/*  */
.recruitment-list {
  background-color: #ffffff;
  padding-bottom: 75px;
  /* font-family: 'Open Sans', sans-serif; */
}
.recruitment-list h2.label {
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 600;
  color: #e8734a;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 30px;
}
.recruitment-list .list-jobs .nav a.active {
  background-color: #e8734a;
  color: #ffffff;
}
.recruitment-list .list-jobs .nav a {
  border: 1px solid #9b8faf;
  outline: none;
  color: #000000;
  font-size: 16px;
  /* font-family: 'Open Sans', sans-serif; */
  text-transform: uppercase;
  background-color: #ffffff;
  border-radius: 30px;
  margin-right: 10px;
}
.recruitment-list .search-job form input {
  padding: 15px 20px;
  width: 100%;
  border: 1px solid #9b8faf;
  border-radius: 30px;
}
button,
input {
  overflow: visible;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  /* font-family: inherit; */
  font-size: inherit;
  line-height: inherit;
}
.job-item {
  padding: 20px;
  border-radius: 10px;
  background-color: #fef4f0;
  margin-bottom: 30px;
  transition: all ease 0.3s;
}
.recruitment-list .search-job form button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  outline: none;
  font-size: 24px;
}
.recruitment-list .search-job form {
  position: relative;
  width: 70%;
  margin: 0 auto;
}
.recruitment-list .search-job form input {
  padding: 15px 20px;
  width: 100%;
  border: 1px solid #9b8faf;
  border-radius: 30px;
}
.recruitment-list .list-jobs .nav {
  border: none;
  margin-bottom: 30px;
  justify-content: center;
}
.recruitment-list .list-jobs .nav a.active {
  background-color: #e8734a;
  color: #ffffff;
}
.recruitment-list .list-jobs .nav a {
  border: 1px solid #9b8faf;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  background-color: #ffffff;
  border-radius: 30px;
  margin-right: 10px;
}
.nav-link-recruitment {
  display: block;
  padding: 0.5rem 1rem;
}
.recruitment-list .list-jobs .job-item .name {
  font-size: 16px;
  color: #e8734a;
  text-transform: uppercase;
  /* font-family: 'Open Sans', sans-serif; */
}
.recruitment-list .list-jobs .job-item i {
  color: #e8734a;
  margin-right: 8px;
  font-size: 14px;
}
.inner .recruitment-list .list-jobs .job-item:hover {
  background-color: #e8734a !important;
}
.recruitment-list .list-jobs .job-item {
  color: #222222;
  font-size: 14px;
  height: 160px;
}

.recruitment-list .list-jobs .job-item:hover {
  color: #222222;
  font-size: 14px;
}

.job-item:hover .deadline i,
.job-item:hover .worktime i {
  color: #fff;
  font-size: 14px;
}

.job-item:hover .location-recruitment img,
.job-item:hover .salary img {
  filter: brightness(0);
  color: #000000;
}
.recruitment-list .image-library .images img {
  border-radius: 10px;
  width: 100%;
  max-height: 560px;
  object-fit: cover;
}
.recruitment-list .curriculum-vitae {
  border-radius: 10px;
  background-color: #e8734a;
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.recruitment-list .watch-more {
  text-align: center;
  border-radius: 30px;
  border: 1px solid #9b8faf;
  padding: 15px 40px;
  background-color: #ffffff;
  display: block;
  margin: 20px auto 0 auto;
}

.recruitment-list .image-library h2 {
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 600;
  color: #e8734a;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 30px;
}

.recruitment-list .curriculum-vitae .form form .sp-column .add-cv {
  cursor: pointer;
  text-align: center;
  display: block;
  border-radius: 30px;
  padding: 15px 25px;
  background-color: #ffffff;
  color: #e8734a;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  margin: 0;
}
.recruitment-list .curriculum-vitae .form form .form-group select {
  height: unset !important;
  background: #ffffff;
  border-right: 15px solid transparent !important;
}
.recruitment-list .curriculum-vitae .form form .sp-column .send {
  padding: 15px 125px;
  background-color: #fde585;
  text-transform: uppercase;
  border-radius: 30px;
  border: none;
  outline: none;
  color: #e8734a;
  font-size: 16px;
  font-weight: 600;
}
.recruitment-list .curriculum-vitae .form form .form-group select {
  padding: 15px 25px !important;
  border-radius: 30px !important;
  outline: none !important;
  border: none !important;
}
.recruitment-list .curriculum-vitae .form form .form-group input {
  padding: 25px 25px !important;
  border-radius: 30px !important;
  outline: none !important;
  border: none !important;
}
@media (max-width: 1024px) {
  .recruitment-list .curriculum-vitae .form form .sp-column {
    flex-direction: column;
  }
}
.recruitment-list .curriculum-vitae .text .label {
  font-size: 24px;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 700;
}
.recruitment-list .curriculum-vitae .text .desc {
  font-size: 14px;
  /* font-family: 'Open Sans', sans-serif; */
}
.recruitment-list .curriculum-vitae .text {
  color: #ffffff;
}
.recruitment {
  margin-top: -50px;
}
</style>
