import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import PageHome from '@/views/Home/PageHome.vue';
import AppRoutes from './app.route';
import AuthRoutes from './auth.route';
import Services from '@/views/Home/PageChild/Services.vue';
import Contact from '@/views/Home/PageChild/Contact.vue';
import About from '@/views/Home/PageChild/About.vue';
import Blog from '@/views/Home/PageChild/Blog.vue';
import Recruitment from '@/views/Home/PageChild/Recruitment.vue';
import DetailRecruitment from '@/views/Home/PageChild/DetailRecruitment.vue';
import DetailRecruitEmployee from '@/views/Home/PageChild/DetailRecruitEmployee.vue';
import DetailRecruitTechEmployee from '@/views/Home/PageChild/DetailRecruitTechEmployee.vue';
import DetailRecruitITEmployee from '@/views/Home/PageChild/DetailRecruititEmployee.vue';
import DetailRecrHRC from '@/views/Home/PageChild/DetailRecrHRC.vue';
import DetailRecAoVme from '@/views/Home/PageChild/DetailRecAoVme.vue';
import DetailBlog from '@/views/Home/PageChild/DetailBLog.vue';
import DetailBlog1 from '@/views/Home/PageChild/DetailBLog1.vue';
import DetailBlog2 from '@/views/Home/PageChild/DetailBlog2.vue';
import DetailBlog3 from '@/views/Home/PageChild/DetailBlog3.vue';
import DetailBlog4 from '@/views/Home/PageChild/DetailBlog4.vue';
import DetailBlog5 from '@/views/Home/PageChild/DetailBlog5.vue';
import VesselSchedule from '@/views/Home/PageChild/VesselSchedule.vue';
import Booking from '@/views/Home/PageChild/Booking.vue';
import Tracking from '@/views/Home/PageChild/Tracking.vue';
import Quote from '@/views/Home/PageChild/Quote.vue';
import BookingDetailPage from '@/views/Home/PageChild/BookingDetailPage.vue';
import BookingDetailPageInfo from '@/views/Home/PageChild/BookingDetailPageInfo.vue';
Vue.use(VueRouter);

const BASE_TITLE = 'Công ty cổ phẩn vận tải Container VIMC';

const routes = [
  ...AuthRoutes,
  ...AppRoutes,
  {
    path: '/',
    name: 'Home',
    component: PageHome,
    meta: { title: 'PageHome' },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: { title: 'Services' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact' },
  },
  {
    path: '/vesselSchedule', // Đường dẫn URL cho Vessel Schedule
    name: 'VesselSchedule',
    component: VesselSchedule,
    meta: { title: 'Vessel Schedule' }, // Tiêu đề trang
  },
  {
    path: '/Quote', // Đường dẫn URL cho Vessel Schedule
    name: 'Quote',
    component: Quote,
    meta: { title: 'Quote' }, // Tiêu đề trang
  },
  {
    path: '/Booking',
    name: 'Booking',
    component: Booking,
    meta: { title: 'Booking' }, // Tiêu đề trang
  },
  {
    path: '/Tracking',
    name: 'Tracking',
    component: Tracking,
    meta: { title: 'Tracking' }, // Tiêu đề trang
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'About' },
  },
  {
    path: '/blogs',
    name: 'Blog',
    component: Blog,
    meta: { title: 'Blogs' },
  },
  {
    path: '/blogs/detail/0',
    name: 'Blog',
    component: DetailBlog,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/blogs/detail/1',
    name: 'Blog',
    component: DetailBlog1,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/blogs/detail/2',
    name: 'Blog',
    component: DetailBlog2,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/blogs/detail/3',
    name: 'Blog',
    component: DetailBlog3,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/blogs/detail/4',
    name: 'Blog',
    component: DetailBlog4,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/blogs/detail/5',
    name: 'Blog',
    component: DetailBlog5,
    meta: { title: 'Blog-Detail' },
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    component: Recruitment,
    meta: { title: 'Recruitment' },
  },
  {
    path: '/recruitment/detail/1',
    name: 'RecruitmentDetail',
    component: DetailRecruitment,
    meta: { title: 'Recruitment-Bussiness-Employee' },
  },
  {
    path: '/recruitment/detail/2',
    name: 'RecruitmentDetail',
    component: DetailRecruitEmployee,
    meta: { title: 'Recruitment-Employee' },
  },
  {
    path: '/recruitment/detail/3',
    name: 'RecruitmentDetail',
    component: DetailRecruitITEmployee,
    meta: { title: 'Recruitment-IT-Employee' },
  },
  {
    path: '/recruitment/detail/4',
    name: 'RecruitmentDetail',
    component: DetailRecruitTechEmployee,
    meta: { title: 'Recruitment-Tech-Engineer' },
  },
  {
    path: '/recruitment/detail/5',
    name: 'RecruitmentDetail',
    component: DetailRecrHRC,
    meta: { title: 'Recruitment-HRC' },
  },
  {
    path: '/recruitment/detail/6',
    name: 'RecruitmentDetail',
    component: DetailRecAoVme,
    meta: { title: 'Recruitment-AO-VME' },
  },
  {
    path: '/booking/detail/:id',
    name: 'BookingDetailPage',
    component: BookingDetailPage,
    meta: { title: 'Booking-Detail' },
  },
  {
    path: '/booking/detail/1/:id',
    name: 'BookingDetailPage',
    component: BookingDetailPageInfo,
    meta: { title: 'Booking-Detail' },
  },
  // {
  //   path: '/container-tracking',
  //   meta: { title: 'Container Tracking' },
  //   component: () => import('@/views/Home/PublicPage/VSLContainerTracking'),
  // },
  // {
  //   path: '/vessel-schedules',
  //   meta: { title: 'Vessel Schedules' },
  //   component: () => import('@/views/Home/PublicPage/VSLVesselSchedule'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Nếu có savedPosition (từ nút quay lại), sử dụng nó
    if (savedPosition) {
      return savedPosition;
    }

    // Nếu URL có query parameters, giữ nguyên vị trí cuộn
    if (Object.keys(to.query).length > 0) {
      return { x: 0, y: window.scrollY }; // Giữ nguyên vị trí cuộn hiện tại
    }

    // Mặc định cuộn về đầu trang với hành vi 'smooth'
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0, behavior: 'smooth' });
      }, 100);
    });
  },
});

const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken');
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - ' + BASE_TITLE;

  if (to.matched[0] && to.matched[0].path === '/app' && !isAuthenticated()) {
    next({
      path: '/auth/login',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
