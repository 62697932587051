var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c("div", {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                            attrs: { id: "carousel-item-caption" },
                          }),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Booking")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "container-booking-tabs" },
            [
              _c(
                "v-tabs",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { "background-color": "transparent" },
                  model: {
                    value: _vm.selectedTab,
                    callback: function ($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-tab",
                    { key: item.tab, staticClass: "text-capitalize" },
                    [_vm._v(" " + _vm._s(item.tab) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            [
              _c("v-tab-item", { key: "0" }, [
                _c(
                  "div",
                  {
                    staticClass: "booking-page",
                    attrs: { id: "booking-page" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container container-booking" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                class: {
                                  "px-1": _vm.$vuetify.breakpoint.mobile,
                                },
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "mt-12": _vm.$vuetify.breakpoint.mobile,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "my-2",
                                        attrs: {
                                          "no-gutters": "",
                                          align: "center",
                                          justify: "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            class: {
                                              "px-1":
                                                _vm.$vuetify.breakpoint.mobile,
                                            },
                                            attrs: { cols: "5" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c("v-card", {
                                                  staticStyle: {
                                                    overflow: "hidden",
                                                  },
                                                  attrs: {
                                                    rounded: "lg",
                                                    width: "100%",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-col", {
                                          class: {
                                            "px-1":
                                              _vm.$vuetify.breakpoint.mobile,
                                          },
                                          attrs: { cols: "7" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tabs-items",
                                  {
                                    model: {
                                      value: _vm.tab,
                                      callback: function ($$v) {
                                        _vm.tab = $$v
                                      },
                                      expression: "tab",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      { key: "0" },
                                      [_c("ContainerTrackingSearch")],
                                      1
                                    ),
                                    _c(
                                      "v-tab-item",
                                      { key: "1" },
                                      [
                                        _c("VesselScheduleSearch", {
                                          attrs: {
                                            "request-open-in-new-tab": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-col", {
                              class: { "px-1": _vm.$vuetify.breakpoint.mobile },
                              attrs: { cols: "7" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "booking-page-table",
                    attrs: { id: "booking-page-table" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container container-booking-table" },
                      [
                        _c(
                          "div",
                          { staticClass: "table-container" },
                          [
                            _vm.isLoading
                              ? _c(
                                  "div",
                                  { staticClass: "loading-container" },
                                  [
                                    _c("v-progress-circular", {
                                      staticClass: "loading-spinner",
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                        size: "50",
                                      },
                                    }),
                                    _c("div", { staticClass: "loading-text" }, [
                                      _c("span", [_vm._v("Loading")]),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("a-table", {
                                  attrs: {
                                    sticky: "",
                                    columns: _vm.columns,
                                    "data-source": _vm.vesselList,
                                    pagination: {
                                      current: _vm.currentPage,
                                      pageSize: 10,
                                      total: _vm.totalPage,
                                    },
                                    scroll: _vm.scrollSettings,
                                  },
                                  on: {
                                    change: _vm.handlePageChange,
                                    rowClick: _vm.handleRowClick,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "bodyCell",
                                      fn: function ({ column }) {
                                        return [
                                          column.key === "operation"
                                            ? [_c("a", [_vm._v("action")])]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "summary",
                                      fn: function () {
                                        return [
                                          _c(
                                            "a-table-summary",
                                            {
                                              attrs: {
                                                fixed: _vm.fixedTop
                                                  ? "top"
                                                  : "bottom",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-table-summary-row",
                                                [
                                                  _c(
                                                    "a-table-summary-cell",
                                                    {
                                                      attrs: {
                                                        index: 0,
                                                        "col-span": 2,
                                                      },
                                                    },
                                                    [
                                                      _c("a-switch", {
                                                        attrs: {
                                                          "checked-children":
                                                            "Fixed Top",
                                                          "un-checked-children":
                                                            "Fixed Top",
                                                        },
                                                        model: {
                                                          value: _vm.fixedTop,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.fixedTop = $$v
                                                          },
                                                          expression:
                                                            "fixedTop",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-table-summary-cell",
                                                    {
                                                      attrs: {
                                                        index: 2,
                                                        "col-span": 8,
                                                      },
                                                    },
                                                    [_vm._v(" Scroll Context ")]
                                                  ),
                                                  _c(
                                                    "a-table-summary-cell",
                                                    { attrs: { index: 10 } },
                                                    [_vm._v(" Fix Right ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c(
                "v-tab-item",
                {
                  key: "1",
                  staticStyle: {
                    "padding-bottom": "80px",
                    transition: "all 0.3s ease-in-out",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "1724px",
                        "margin-left": "94px",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "py-4 pl-7 pr-2",
                          staticStyle: {
                            "background-color": "#f5f5f5",
                            border: "1px solid #ddd",
                          },
                          attrs: { rounded: "lg" },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "search-box" },
                            [
                              _c(
                                "v-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSearch.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "grid",
                                        "grid-template-columns": "2fr 1fr",
                                        "grid-gap": "32px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            position: "relative",
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "departurePort" } },
                                            [_vm._v("Địa điểm")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.departurePort,
                                                expression: "departurePort",
                                              },
                                            ],
                                            staticClass: "address-warehouse",
                                            attrs: {
                                              id: "departurePort",
                                              type: "text",
                                              placeholder: "Địa điểm",
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.departurePort,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.departurePort =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "icon-warehouse" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-map-marker",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            margin: "0 0 0 -10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "auto",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "datesWarehouse",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Ngày đi và ngày đến"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "full-width",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                id: "datesWarehouse",
                                                                readonly: "",
                                                                outlined: "",
                                                                placeholder:
                                                                  "Ngày đi và ngày đến",
                                                                dense: "",
                                                                clearable: "",
                                                                "hide-details":
                                                                  "auto",
                                                                "prepend-inner-icon":
                                                                  "mdi-calendar",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formattedDatesWarehouse,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.formattedDatesWarehouse =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "formattedDatesWarehouse",
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.dateMenuWarehouse,
                                                callback: function ($$v) {
                                                  _vm.dateMenuWarehouse = $$v
                                                },
                                                expression: "dateMenuWarehouse",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  range: "",
                                                  "no-title": "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateDatesWarehouse,
                                                },
                                                model: {
                                                  value: _vm.datesWarehouse,
                                                  callback: function ($$v) {
                                                    _vm.datesWarehouse = $$v
                                                  },
                                                  expression: "datesWarehouse",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-2",
                                      attrs: {
                                        justify: "space-between",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: _vm.$t("phone"),
                                              outlined: "",
                                              clearable: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon": "mdi-phone",
                                            },
                                            model: {
                                              value: _vm.phone,
                                              callback: function ($$v) {
                                                _vm.phone = $$v
                                              },
                                              expression: "phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: "Email",
                                              outlined: "",
                                              clearable: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon": "mdi-email",
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function ($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                "margin-top": "-15px",
                                                color: "white",
                                              },
                                              attrs: {
                                                block: "",
                                                color: "#e6734f",
                                              },
                                              on: { click: _vm.onSearch },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Request")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-tab-item",
                {
                  key: "2",
                  staticStyle: {
                    "padding-bottom": "80px",
                    transition: "all 0.3s ease-in-out",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "1724px",
                        "margin-left": "94px",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "py-4 pl-7 pr-2",
                          staticStyle: {
                            "background-color": "#f5f5f5",
                            border: "1px solid #ddd",
                          },
                          attrs: { rounded: "lg" },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "search-box" },
                            [
                              _c(
                                "v-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSearchTruck.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-2",
                                      attrs: {
                                        justify: "space-between",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: "Điểm đi",
                                              required: "",
                                              clearable: "",
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon":
                                                "mdi-map-marker",
                                            },
                                            model: {
                                              value: _vm.departurePortTruck,
                                              callback: function ($$v) {
                                                _vm.departurePortTruck = $$v
                                              },
                                              expression: "departurePortTruck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: "Điểm đến",
                                              required: "",
                                              clearable: "",
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon":
                                                "mdi-map-marker",
                                            },
                                            model: {
                                              value: _vm.arrivalPortTruck,
                                              callback: function ($$v) {
                                                _vm.arrivalPortTruck = $$v
                                              },
                                              expression: "arrivalPortTruck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                label:
                                                                  "Ngày đi và ngày đến",
                                                                readonly: "",
                                                                clearable: "",
                                                                outlined: "",
                                                                dense: "",
                                                                "hide-details":
                                                                  "auto",
                                                                "prepend-inner-icon":
                                                                  "mdi-calendar",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formattedDatesTruck,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.formattedDatesTruck =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "formattedDatesTruck",
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.dateMenuTruck,
                                                callback: function ($$v) {
                                                  _vm.dateMenuTruck = $$v
                                                },
                                                expression: "dateMenuTruck",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  range: "",
                                                  "no-title": "",
                                                },
                                                on: {
                                                  change: _vm.updateDatesTruck,
                                                },
                                                model: {
                                                  value: _vm.datesTruck,
                                                  callback: function ($$v) {
                                                    _vm.datesTruck = $$v
                                                  },
                                                  expression: "datesTruck",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-2",
                                      attrs: {
                                        justify: "space-between",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: "Số điện thoại",
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon": "mdi-ferry",
                                            },
                                            model: {
                                              value: _vm.phoneNumberTruck,
                                              callback: function ($$v) {
                                                _vm.phoneNumberTruck = $$v
                                              },
                                              expression: "phoneNumberTruck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-bottom": "16px",
                                            },
                                            attrs: {
                                              label: "Email",
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "prepend-inner-icon": "mdi-ferry",
                                            },
                                            model: {
                                              value: _vm.emailTruck,
                                              callback: function ($$v) {
                                                _vm.emailTruck = $$v
                                              },
                                              expression: "emailTruck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                "margin-top": "-15px",
                                                color: "white",
                                              },
                                              attrs: {
                                                block: "",
                                                color: "#e6734f",
                                              },
                                              on: { click: _vm.onSearchTruck },
                                            },
                                            [_vm._v(" Request ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-tab-item",
                { key: "2" },
                [
                  _c(
                    "v-card",
                    { staticClass: "py-4 pl-7 pr-2", attrs: { rounded: "lg" } },
                    [
                      _c("v-card-subtitle", { staticClass: "subheading" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("label-tracking-container")) + " "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "booking-label",
                            style: { marginLeft: _vm.computedMargin },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("label-tracking-booking")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "search-box" },
                        [
                          _c(
                            "v-form",
                            {
                              attrs: { onSubmit: "return false;" },
                              on: { submit: _vm.onTrackingContainer },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass:
                                      "mr-4 custom-select-field fixed-width",
                                    attrs: {
                                      id: "container-autocomplete-field",
                                    },
                                    model: {
                                      value: _vm.containerNo,
                                      callback: function ($$v) {
                                        _vm.containerNo = $$v
                                      },
                                      expression: "containerNo",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticClass:
                                      "mr-4 custom-select-field fixed-width",
                                    attrs: { id: "booking-select-field" },
                                    model: {
                                      value: _vm.bookingNo,
                                      callback: function ($$v) {
                                        _vm.bookingNo = $$v
                                      },
                                      expression: "bookingNo",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-10 py-6",
                                      staticStyle: {
                                        "text-transform": "none",
                                        "font-size": "16px",
                                        color: "#fff !important",
                                        "background-color":
                                          "#e6734f !important",
                                      },
                                      attrs: { large: "" },
                                    },
                                    [
                                      _c("v-icon", [_vm._v(" mdi-magnify ")]),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("search")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FooterHome"),
      _vm._m(0),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }