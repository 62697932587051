<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="services" />
    <!-- Banner -->
    <div
      id="carousel-2"
      class="carousel slide carousel-fade carousel-slide"
      data-ride="carousel"
      data-interval="6000"
    >
      <div
        class="carousel-inner"
        role="listbox"
      >
        <div class="carousel-item active">
          <img
            src="../../../libhome/img/vimc accelerating-digital-transformation.png"
            alt="responsive image"
            class="d-block img-fluid"
          />

          <div class="carousel-caption">
            <div>
              <div class="transx_content_slide_wrapper">
                <div class="transx_content_container">
                  <div class="transx_content_wrapper_1">
                    <div
                      class="transx_up_heading_overlay transx_overlay_align_center"
                      id="carousel-item-caption"
                    >
                      <!-- VIMC&nbsp;LINES -->
                    </div>
                    <h2 class="transx_content_slider_title">
                      {{ $t('Tracking') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tracking-page"
      id="tracking-page"
    >
      <div class="container container-tracking">
        <v-row>
          <v-col
            cols="12"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
            <div>
              <div
                :class="{
                  'mt-12': $vuetify.breakpoint.mobile,
                }"
              >
                <v-row no-gutters>
                  <!-- <v-btn text small to="/app" target="_blank">
              Request a quote
              <v-icon right small>mdi-arrow-top-right</v-icon>
            </v-btn> -->
                </v-row>
                <v-row
                  no-gutters
                  class="my-2"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="5"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-card
                        rounded="lg"
                        width="100%"
                        style="overflow: hidden"
                      >
                      </v-card>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="7"
                    :class="{
                      'px-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                  </v-col>
                </v-row>
              </div>

              <v-tabs-items v-model="tab">
                <v-tab-item key="0">
                  <ContainerTrackingSearch />
                </v-tab-item>
                <!-- <v-tab-item key="0">
                  <VesselScheduleSearch request-open-in-new-tab />
                </v-tab-item> -->
              </v-tabs-items>
            </div>
          </v-col>
          <v-col
            cols="7"
            :class="{
              'px-1': $vuetify.breakpoint.mobile,
            }"
          >
          </v-col>
        </v-row>
      </div>
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';
import { nextTick } from 'vue';
export default {
  components: {
    VesselScheduleSearch,
    ContainerTrackingSearch,
    NavBarHome,
    NavBarLeftHome,
    FooterHome,
  },
  data: () => ({
    tab: 0,
    searchMenuTabs: [
      {
        key: 0,
        title: 'Tracking',
        icon: 'mdi-cube-scan',
        color: 'brown',
        colorModifier: 'lighten-5',
        colorModifierActive: 'lighten-3',
      },
      // {
      //   key: 1,
      //   title: 'Booking',
      //   icon: 'mdi-focus-field',
      //   color: 'light-blue',
      //   colorModifier: 'lighten-5',
      //   colorModifierActive: 'lighten-3',
      // },
      // {
      //   key: 2,
      //   title: 'Debit',
      //   icon: 'mdi-file-document-multiple-outline',
      //   color: 'green',
      //   colorModifier: 'lighten-5',
      //   colorModifierActive: 'lighten-3',
      // },
    ],
  }),
  computed: {},
  created() {
    const { tracking_no, debit_search_no, pol_code, pod_code } =
      this.$route.query;
    if (tracking_no) {
      this.tab = 0;
    }
    if (pol_code || pod_code) {
      this.tab = 1;
    }
    // if (debit_search_no){
    //   this.tab = 2;
    // }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollToElement = (element, offset = 0) => {
        const start = window.pageYOffset;
        const end =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        const distance = end - start;
        const duration = 3000; // Thời gian cuộn (ms)
        let startTime = null;

        const scroll = (currentTime) => {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          const easing =
            progress < 0.5
              ? 4 * progress * progress * progress
              : 1 - Math.pow(-2 * progress + 2, 3) / 2;
          window.scrollTo(0, start + distance * easing);
          if (timeElapsed < duration) requestAnimationFrame(scroll);
        };

        requestAnimationFrame(scroll);
      };

      const trackingElement = document.getElementById('carousel-item-caption');
      if (trackingElement) {
        const headerHeight = document.querySelector('.navbar').offsetHeight;
        scrollToElement(trackingElement, headerHeight);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-btn::before {
    background-color: transparent;
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2 !important;
}

.carousel-slide {
  margin-bottom: 30px !important;
}

#tracking-page {
  display: block; /* Đảm bảo phần tử không bị ẩn */
  visibility: visible; /* Đảm bảo phần tử có thể nhìn thấy */
  margin-bottom: 40px;
  background: #f2f2f2;
}

.container-tracking {
  max-width: 1730px !important;
  padding: 14px 0 14px 23px;
}

@media screen and (max-width: 1181px) {
  :deep(.mt-12) {
    margin: 0 !important;
  }
}

@media screen and (max-width: 1025px) {
  :deep(.mt-12) {
    margin: 0 !important;
  }
}

footer {
  padding: 20px 0; /* Điều chỉnh padding nếu cần */
}
</style>
