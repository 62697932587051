var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                              attrs: { id: "carousel-item-caption" },
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Booking")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "booking-page", attrs: { id: "booking-page" } },
        [
          _c(
            "div",
            { staticClass: "container container-booking" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: {
                        "px-1": _vm.$vuetify.breakpoint.mobile,
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "mt-12": _vm.$vuetify.breakpoint.mobile,
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class: {
                                        "px-1": _vm.$vuetify.breakpoint.mobile,
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-card", {
                                            staticStyle: { overflow: "hidden" },
                                            attrs: {
                                              rounded: "lg",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    class: {
                                      "px-1": _vm.$vuetify.breakpoint.mobile,
                                    },
                                    attrs: { cols: "7" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "0" },
                                [_c("ContainerTrackingSearch")],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "1" },
                                [
                                  _c("VesselScheduleSearch", {
                                    attrs: { "request-open-in-new-tab": "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", {
                    class: {
                      "px-1": _vm.$vuetify.breakpoint.mobile,
                    },
                    attrs: { cols: "7" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-app",
        [
          _c(
            "v-main",
            { staticClass: "grey lighten-4" },
            [
              _c(
                "v-container",
                { staticClass: "pa-6" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center mb-6",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pl-0",
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.goBack },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-chevron-left"),
                          ]),
                          _vm._v(" Back "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.newSearch },
                        },
                        [
                          _vm._v(" New search "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-chevron-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h1", { staticClass: "text-h4 font-weight-bold mb-6" }, [
                    _vm._v(
                      " Quotation " +
                        _vm._s(_vm.quotationId) +
                        " | USD " +
                        _vm._s(_vm.price) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.vesselDetails
                            ? _c(
                                "v-card",
                                { staticClass: "mb-6" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        size: "40",
                                                        color: "grey lighten-2",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { medium: "" },
                                                        },
                                                        [_vm._v("mdi-ferry")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "subtitle-1 font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.vesselDetails
                                                                .vessel.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption red--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.status)
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-package-variant")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.containerSize)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-clock-outline")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calculateDaysBetween(
                                                          _vm.vesselDetails.eta,
                                                          _vm.vesselDetails.etd
                                                        )
                                                      ) +
                                                      " days"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-airplane"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-6",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center mb-2",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-anchor")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.vesselDetails
                                                            .pol_terminal.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  value: "100",
                                                  color: "primary",
                                                  height: "4",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-end",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-map-marker")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.vesselDetails
                                                            .pod_terminal.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "mt-4" }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              gap: "20px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "1",
                                                  "max-width": "530px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      display: "block",
                                                      "margin-bottom": "4px",
                                                    },
                                                    attrs: {
                                                      for: "phoneNumber",
                                                    },
                                                  },
                                                  [_vm._v(" Phone Number ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "mdi mdi-phone",
                                                      staticStyle: {
                                                        position: "absolute",
                                                        left: "8px",
                                                        "font-size": "20px",
                                                        color: "#888",
                                                      },
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.phoneNumber,
                                                          expression:
                                                            "phoneNumber",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        padding:
                                                          "8px 8px 8px 36px",
                                                        width: "100%",
                                                        border:
                                                          "1px solid #ccc",
                                                        "border-radius": "4px",
                                                      },
                                                      attrs: {
                                                        id: "phoneNumber",
                                                        type: "tel",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.phoneNumber,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.phoneNumber =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "1",
                                                  "max-width": "530px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      display: "block",
                                                      "margin-bottom": "4px",
                                                    },
                                                    attrs: { for: "email" },
                                                  },
                                                  [_vm._v(" Email ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                      display: "flex",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "mdi mdi-email",
                                                      staticStyle: {
                                                        position: "absolute",
                                                        left: "8px",
                                                        "font-size": "20px",
                                                        color: "#888",
                                                      },
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.email,
                                                          expression: "email",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        padding:
                                                          "8px 8px 8px 36px",
                                                        width: "100%",
                                                        border:
                                                          "1px solid #ccc",
                                                        "border-radius": "4px",
                                                      },
                                                      attrs: {
                                                        id: "email",
                                                        type: "input",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.email,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.email =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "text-right mt-4" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "subtitle-1 font-weight-bold mb-2",
                                            },
                                            [
                                              _vm._v(
                                                " USD " +
                                                  _vm._s(_vm.price) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.isLoadingRequest,
                                              },
                                              on: { click: _vm.handleRequest },
                                            },
                                            [_vm._v("Request")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-6" },
                            [
                              _c("v-card-title", [_vm._v("Rate breakdown")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-timeline",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-timeline-item",
                                        {
                                          attrs: {
                                            small: "",
                                            "fill-dot": "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between",
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-medium",
                                                  },
                                                  [_vm._v(_vm._s(_vm.origin))]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption grey--text",
                                                  },
                                                  [_vm._v("Port")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-timeline-item",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "blue--text mb-2" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [_vm._v("mdi-ferry")]
                                              ),
                                              _vm._v(" Sea FCL "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "caption" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.distance) +
                                                  " km, " +
                                                  _vm._s(_vm.transitDays) +
                                                  " days "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "caption" },
                                            [
                                              _vm._v(
                                                " Ocean Freight (FIFO) USD " +
                                                  _vm._s(_vm.oceanFreight) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-timeline-item",
                                        {
                                          attrs: {
                                            small: "",
                                            "fill-dot": "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between",
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.destination
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption grey--text",
                                                  },
                                                  [_vm._v("Port")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "mb-6" },
                            [
                              _c("v-card-title", [_vm._v("Validity")]),
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("mdi-calendar")]
                                    ),
                                    _c("span", [_vm._v(_vm._s(_vm.validity))]),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [_vm._v("Departures")]),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0 overflow-hidden" },
                                [
                                  _c(
                                    "v-list",
                                    { staticStyle: { "max-width": "500px" } },
                                    _vm._l(
                                      _vm.departures,
                                      function (departure) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: departure.date,
                                            staticClass: "overflow-hidden",
                                            staticStyle: {
                                              "max-width": "500px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                departure.date
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("mdi-ship")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                departure.vessel
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              departure.code
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _c("div", { staticClass: "map-container" }, [
                                  _c("iframe", {
                                    staticStyle: {
                                      border: "0",
                                      "min-height": "500px",
                                    },
                                    attrs: {
                                      width: "100%",
                                      height: "100%",
                                      loading: "lazy",
                                      allowfullscreen: "",
                                      src: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.6347957964826!2d105.8378493751975!3d21.00727158851638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac7802b8752b%3A0xca91cd914f429947!2zVOG7lW5nIGPDtG5nIHR5IEjDoG5nIEjhuqNpIFZp4buHdCBOYW0gLSBWSU1D!5e0!3m2!1svi!2s!4v1711503233131!5m2!1svi!2s`,
                                    },
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FooterHome"),
      _vm._m(0),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }